<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="toplinks pb-2 d-flex align-items-center border-bottom">
                    <li class="ps-0"><a @click.prevent="profileTab = 'indicator'" :class="(profileTab == 'indicator')?'active':''" href="javascript:void(0)">Eco indicator</a></li>
                    <li><a @click.prevent="profileTab = 'Sentiments'" :class="(profileTab == 'Sentiments')?'active':''" href="javascript:void(0)">Sentiments</a></li>
                </ul>
                <div class="table-responsive snapTable" v-if="profileTab == 'indicator'">
                    <table class="table borderedTable">
                        <tbody>
                            <tr>
                                <td class="p-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>US CPI</td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Actual</span>
                                                            <span class="lighttext">9.1%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Previous</span>
                                                            <span class="lighttext">8%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Forecast</span>
                                                            <span class="lighttext">8.5%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div class="progress-round ">
                                                            <img src="assets/images/forcastChart.svg" alt="Icon" title="Accuracy">
                                                            <p>Accuracy</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                        <div class="row g-0 showTableAccordian mx-0" colspan="9">
                                            <div class="col-4 border-end lowOpcity">
                                                <img class="d-block mx-auto mt-4" src="assets/images/chart/predictionChart.svg" alt="Chart" title="Prediction Chart">
                                            </div>
                                            <div class="col-8">
                                                <div class="d-flex align-items-center justify-content-between p-3">
                                                    <h6>Post Analysis <span class="green medium">USD</span></h6>
                                                    <ul class="d-flex align-items-center tradeHrs mb-0">
                                                        <li><a href="javascript:void(0)">1hr</a></li>
                                                        <li><a href="javascript:void(0)">2hr</a></li>
                                                        <li><a href="javascript:void(0)">3hr</a></li>
                                                        <li><a href="javascript:void(0)">4hr</a></li>
                                                        <li><a href="javascript:void(0)">5hr</a></li>
                                                    </ul>
                                                </div>
                                                <div class="px-3">
                                                    <img class="w-100 mb-3" src="assets/images/chart/postAnalysis-chart.svg" alt="Chart" title="Post Analysis Chart">
                                                </div>
                                                <div class="yourVote d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <span class="me-1">Your Vote On </span>
                                                        <span><img width="13" height="13" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>                                           
                                                        <span class="mx-1">USD</span>
                                                        <span class="green"><vue-feather size="15" type="arrow-up"></vue-feather></span>
                                                    </div>
                                                    <div class="d-flex">
                                                      <p class="mb-0 me-2">Accuracy</p>
                                                        <img src="assets/images/chart/resultChart.svg" alt="Chart" title="Your Vote Accuracy">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0 border-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>US CPI</td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Actual</span>
                                                            <span class="lighttext">9.1%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Previous</span>
                                                            <span class="lighttext">8%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Forecast</span>
                                                            <span class="lighttext">8.5%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div class="progress-round ">
                                                            <img src="assets/images/forcastChart.svg" alt="Icon" title="Accuracy">
                                                            <p>Accuracy</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0 border-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>US CPI</td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Actual</span>
                                                            <span class="lighttext">9.1%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Previous</span>
                                                            <span class="lighttext">8%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Forecast</span>
                                                            <span class="lighttext">8.5%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div class="progress-round ">
                                                            <img src="assets/images/forcastChart.svg" alt="Icon" title="Accuracy">
                                                            <p>Accuracy</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0 border-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>US CPI</td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Actual</span>
                                                            <span class="lighttext">9.1%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Previous</span>
                                                            <span class="lighttext">8%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Forecast</span>
                                                            <span class="lighttext">8.5%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div class="progress-round ">
                                                            <img src="assets/images/forcastChart.svg" alt="Icon" title="Accuracy">
                                                            <p>Accuracy</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0 border-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>US CPI</td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Actual</span>
                                                            <span class="lighttext">9.1%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Previous</span>
                                                            <span class="lighttext">8%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-2">Forecast</span>
                                                            <span class="lighttext">8.5%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div class="progress-round ">
                                                            <img src="assets/images/forcastChart.svg" alt="Icon" title="Accuracy">
                                                            <p>Accuracy</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive snapTable maxColumn" v-if="profileTab == 'Sentiments'">
                    <table class="table borderedTable">
                        <tbody>
                            <tr>
                                <td class="p-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                        <div class="justify-content-center g-0 showTableAccordian mx-0" colspan="9">
                                            <div class="voteAccuracy">
                                                <div class="px-3">
                                                    <img class="w-100 mb-3" src="assets/images/chart/sentimentsChart.svg" alt="Chart" title="Post Analysis Chart">
                                                </div>
                                                <div class="yourVote d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <span class="me-1">Your Vote On </span>
                                                        <span><img width="13" height="13" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>                                           
                                                        <span class="mx-1">USD</span>
                                                        <span class="green"><vue-feather size="15" type="arrow-up"></vue-feather></span>
                                                    </div>
                                                    <div class="d-flex">
                                                      <p class="mb-0 me-2">Accuracy</p>
                                                        <img src="assets/images/chart/resultChart.svg" alt="Chart" title="Your Vote Accuracy">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0 border-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0 border-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0 border-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0 border-0">
                                    <table class="table w-100">
                                        <tr>
                                            <table class="table w-100">
                                                <tr>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <span class="me-1">21/2022</span>
                                                            <span class="lighttext d-flex align-items-center "><vue-feather class="me-1" size="16" type="clock"></vue-feather> 18:30</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="d-flex align-items-center">
                                                            <img width="23" height="19" src="assets/images/tradeGreen.svg" alt="Trade" title="Trade Chart">
                                                            <p class="mx-2">Impact  Analysis </p>
                                                            <p class="d-flex align-items-center">
                                                                <span><img width="12" height="12" src="assets/images/country_flag/united states.webp" alt="Flag" title="United States"></span>
                                                                <span class="mx-1">USD</span>
                                                                <span class="green">35%</span>
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" :class="details?'added':'add'" class="tradeBtn" @click="details = !details"><vue-feather :type="details?'minus':'plus'" size="16px"></vue-feather></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            profileTab:'indicator',
            details:false,
        }
    },    
}
</script>

<template>
    <div class="container">
        <div class="row" v-show="demoWatchlist == '1'">
             <div class="col-12 d-flex align-items-center justify-content-between mb-3 flex-wrap">
                <h5 class="mb-0">All Discussions</h5>
                <a data-bs-toggle="modal" data-bs-target="#exampleModal" href="javascript:void(0)" class="grd_btn small"><vue-feather class="me-1" size="16px" type="message-square"></vue-feather> Start New Discussion</a>
            </div>
            <div class="col-12 col-lg-4 space">
                <div class="d-flex align-items-center justify-content-between mb-3">
                        <h6 class="mb-0 medium">Recent Disussions</h6>
                        <a @click="demoWatchlist = '2'"  href="javascript:void(0)" class="green">View all</a>
                    </div>
                <div class="card-body myWatchList">
                    <h6>Investment Learning</h6>
                    <div class="d-flex align-items-start justify-content-between border-bottom mb-2 pb-2">
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Views</p>
                            <h6 class="f-14">100K</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Users</p>
                            <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
                        </div>
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Replies</p>
                            <h6 class="f-14">12K</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Activity</p>
                            <h6 class="f-14">Today, 12:41 PM</h6>
                        </div>
                    </div>
                    <div class="singleLine">
                        <div class="block1">
                            <p class="mb-0 lighttext f-12">Last message</p>
                            <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                        </div>
                    </div>
                </div>
                <div class="card-body myWatchList">
                    <h6>Investment Learning</h6>
                    <div class="d-flex align-items-start justify-content-between border-bottom mb-2 pb-2">
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Views</p>
                            <h6 class="f-14">100K</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Users</p>
                            <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
                        </div>
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Replies</p>
                            <h6 class="f-14">12K</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Activity</p>
                            <h6 class="f-14">Today, 12:41 PM</h6>
                        </div>
                    </div>
                    <div class="singleLine">
                        <div class="block1">
                            <p class="mb-0 lighttext f-12">Last message</p>
                            <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 space">
                <h6 class="mb-3 medium">Communities</h6>
                <div class="card-body myWatchList">
                    <div class="d-flex align-items-center p-2 justify-content-between comBorder">
                        <div class="communities d-flex">
                            <img class="me-2" src="assets/images/icoIcon1.svg" alt="Icon" title="communities">
                            <div class="flex-grow-1">
                                <h6 class="mb-0">ForexPips</h6>
                                <span class="mb-1 f-12 lighttext">2 min ago</span>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="greenBtn">Unfollow</a>
                    </div>
                    <div class="d-flex align-items-center p-2 justify-content-between comBorder">
                        <div class="communities d-flex">
                            <img class="me-2" src="assets/images/icoIcon2.svg" alt="Icon" title="communities">
                            <div class="flex-grow-1">
                                <h6 class="mb-0">GoldScam</h6>
                                <span class="mb-1 f-12 lighttext">2 min ago</span>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="greenBtn">Unfollow</a>
                    </div>
                    <div class="d-flex align-items-center p-2 justify-content-between comBorder">
                        <div class="communities d-flex">
                            <img class="me-2" src="assets/images/icoIcon3.svg" alt="Icon" title="communities">
                            <div class="flex-grow-1">
                                <h6 class="mb-0">FXMasters</h6>
                                <span class="mb-1 f-12 lighttext">2 min ago</span>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="greenBtn">Unfollow</a>
                    </div>
                    <div class="d-flex align-items-center p-2 justify-content-between comBorder">
                        <div class="communities d-flex">
                            <img class="me-2" src="assets/images/icoIcon4.svg" alt="Icon" title="communities">
                            <div class="flex-grow-1">
                                <h6 class="mb-0">CatchMarket</h6>
                                <span class="mb-1 f-12 lighttext">2 min ago</span>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="greenBtn">Unfollow</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 space">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <h6 class="mb-0 medium">Featured forum</h6>
                </div>
                <div class="card-body myWatchList">
                    <h6>Investment Learning</h6>
                    <div class="d-flex align-items-start justify-content-between border-bottom mb-2 pb-2">
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Views</p>
                            <h6 class="f-14">100K</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Users</p>
                            <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
                        </div>
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Replies</p>
                            <h6 class="f-14">12K</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Activity</p>
                            <h6 class="f-14">Today, 12:41 PM</h6>
                        </div>
                    </div>
                    <div class="singleLine">
                        <div class="block1">
                            <p class="mb-0 lighttext f-12">Last message</p>
                            <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                        </div>
                    </div>
                </div>
                <div class="card-body myWatchList">
                    <h6>Featured forum</h6>
                    <div class="d-flex align-items-start justify-content-between border-bottom mb-2 pb-2">
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Views</p>
                            <h6 class="f-14">100K</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Users</p>
                            <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
                        </div>
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Replies</p>
                            <h6 class="f-14">12K</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 f-12 lighttext">Activity</p>
                            <h6 class="f-14">Today, 12:41 PM</h6>
                        </div>
                    </div>
                    <div class="singleLine">
                        <div class="block1">
                            <p class="mb-0 lighttext f-12">Last message</p>
                            <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 space">
                <h6 class="mb-3 medium">Trending communities</h6>
                <div class="card-body myWatchList">
                    <div class="d-flex align-items-center border-bottom pb-2 mb-2 justify-content-between">
                        <div class="communities d-flex">
                            <img class="me-2" src="assets/images/icoIcon2.svg" alt="Icon" title="communities">
                            <div class="flex-grow-1">
                                <h6 class="mb-0">GoldScam</h6>
                                <span class="mb-1 f-12 lighttext">2 min ago</span>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="greenBtn">follow</a>
                    </div>
                    <div class="singleLine">
                        <p class="mb-0 lighttext f-12">Last message</p>
                        <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                    </div>
                    <div class="singleIcon text-end">
                        <img class="d-block ms-auto mt-2" src="assets/images/multi.png" alt="Chart" title="Multi">
                    </div>
                </div>
                <div class="card-body myWatchList">
                    <div class="d-flex align-items-center border-bottom pb-2 mb-2 justify-content-between">
                        <div class="communities d-flex">
                            <img class="me-2" src="assets/images/icoIcon3.svg" alt="Icon" title="communities">
                            <div class="flex-grow-1">
                                <h6 class="mb-0">FXMasters</h6>
                                <span class="mb-1 f-12 lighttext">2 min ago</span>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="greenBtn">follow</a>
                    </div>
                    <div class="singleLine">
                        <p class="mb-0 lighttext f-12">Last message</p>
                        <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                    </div>
                    <div class="singleIcon text-end">
                        <img class="d-block ms-auto mt-2" src="assets/images/multi.png" alt="Chart" title="Multi">
                    </div>
                </div>
                <div class="card-body myWatchList">
                    <div class="d-flex align-items-center border-bottom pb-2 mb-2 justify-content-between">
                        <div class="communities d-flex">
                            <img class="me-2" src="assets/images/icoIcon4.svg" alt="Icon" title="communities">
                            <div class="flex-grow-1">
                                <h6 class="mb-0">CatchMarket</h6>
                                <span class="mb-1 f-12 lighttext">2 min ago</span>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="greenBtn">follow</a>
                    </div>
                    <div class="singleLine">
                        <p class="mb-0 lighttext f-12">Last message</p>
                        <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                    </div>
                    <div class="singleIcon text-end">
                        <img class="d-block ms-auto mt-2" src="assets/images/multi.png" alt="Chart" title="Multi">
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 space">
                <h6 class="mb-3 medium">Economic Calendar</h6>
                <div class="card-body myWatchList">
                    <div class="table-responsive economic">
                      <table class="table table-sm border-0 bg-white">
                         <thead>
                          <tr>
                            <th class="date medium">April 30</th>
                            <th colspan="3" class="events text-end">21 Events </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="active borderAdd bt">
                            <td class="active-td">
                              <span class="d-flex align-items-center">
                                <img src="assets/images/economic-flag1.svg" class="rounded-circle me-2" alt="">
                                UK
                              </span>
                            </td>
                            <td>01:00am</td>
                            <td>Markit/CIPS Manufacturing PMI</td>
                            <td><a href="#"><vue-feather type="chevron-down"></vue-feather></a></td>
                          </tr>
                          <tr class="subactive-tr">
                            <td colspan="4">
                              <div class="d-flex align-items-center justify-content-between openDom">
                                <span class="subactive-td">
                                  <div class="f-16">00:10:45</div>
                                  <div class="f-12">Time 18:45</div>
                                </span>
                                <span align="center" class="active-time-int middle">Actual --.--</span>
                                <span colspan="2" class="subactive-td2">
                                <div class="forecast">
                                  <span class="f-12 me-2">Forecast</span>
                                  <span class="f-16">60.70</span>
                                  </div>
                                  <div class="previe">
                                    <span class="f-12 me-2">Previous</span>
                                    <span class="f-16">58.70</span>
                                  </div>        
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr class="borderAdd et">
                            <td class="active-td2">
                              <span class="d-flex align-items-center">
                                <img src="assets/images/economic-flag1.svg" class="rounded-circle me-2" alt="">
                                UK
                              </span>
                            </td>
                            <td>10:00am</td>
                            <td>GfK Consumer Confidence (May)</td>
                            <td><a href="#"><vue-feather type="chevron-down"></vue-feather></a></td>
                          </tr>
                          <tr class="borderAdd rt">
                            <td class="active-td3">
                              <span class="d-flex align-items-center">
                                <img src="assets/images/economic-flag2.svg" class="rounded-circle me-2" alt="">
                                GER
                              </span>
                            </td>
                            <td>02:00am</td>
                            <td>Inflation Rate YoY (Apr)</td>
                            <td><a href="#"><vue-feather type="chevron-down"></vue-feather></a></td>
                          </tr>
                          <tr class="borderAdd bt">
                            <td class="active-td">
                              <span class="d-flex align-items-center">
                                <img src="assets/images/economic-flag3.svg" class="rounded-circle me-2" alt="">
                                CAN
                              </span>
                            </td>
                            <td>05:00am</td>
                            <td>Fed Interest Rate Decision</td>
                            <td><a href="#"><vue-feather type="chevron-down"></vue-feather></a></td>
                          </tr>
                          <tr>
                            <td class="medium">May 01</td>
                            <td colspan="3" class="text-end">20 Events </td>
                          </tr>
                          <tr role="button" data-href="#">
                            <td class="medium">May 17</td>
                            <td colspan="3" class="text-end">21 Events </td>
                          </tr>
                          <tr role="button" data-href="#">
                            <td class="medium">May 21</td>
                            <td colspan="3" class="text-end">12 Events </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 space">
                <h6 class="mb-3 medium">Recent News</h6>
                <div class="media d-flex">
                    <div class="flex-shrink-0">
                        <img src="assets/images/insight1.jpg" alt="Image">
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="medium mb-1"><a class="d-block" href="#"> US 10Y yields point to some consolidation around 1.28%...</a></h6>
                        <p class="clock f-12 mb-1"><vue-feather type="clock" class="clock-icon"></vue-feather> 7 hours ago</p>
                        <p>The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                    </div>
                </div>
                <div class="media d-flex">
                    <div class="flex-shrink-0">
                        <img src="assets/images/insight1.jpg" alt="Image">
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="medium mb-1"><a class="d-block" href="#"> US 10Y yields point to some consolidation around 1.28%...</a></h6>
                        <p class="clock f-12 mb-1"><vue-feather type="clock" class="clock-icon"></vue-feather> 7 hours ago</p>
                        <p>The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                    </div>
                </div>
                <div class="media d-flex">
                    <div class="flex-shrink-0">
                        <img src="assets/images/insight1.jpg" alt="Image">
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="medium mb-1"><a class="d-block" href="#"> US 10Y yields point to some consolidation around 1.28%...</a></h6>
                        <p class="clock f-12 mb-1"><vue-feather type="clock" class="clock-icon"></vue-feather> 7 hours ago</p>
                        <p>The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                    </div>
                </div>
                <div class="media d-flex">
                    <div class="flex-shrink-0">
                        <img src="assets/images/insight1.jpg" alt="Image">
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="medium mb-1"><a class="d-block" href="#"> US 10Y yields point to some consolidation around 1.28%...</a></h6>
                        <p class="clock f-12 mb-1"><vue-feather type="clock" class="clock-icon"></vue-feather> 7 hours ago</p>
                        <p>The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 space">
                <h6 class="mb-3 medium">Most Volatile Pair</h6>
                <div class="card-body responsiveScrollar p-0">
                    <ul class="moverList">
                        <li class="shadowBlock">
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="flex-grow-1">
                                    <span class="f-15">EURUSD</span>
                                    <p class="mb-0 f-14 green">+0.76%</p>
                                </span>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">LTP</span>
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">Change%</span>
                                <p class="mb-0 f-14">30,640.00</p>
                            </span>
                            <span class="blockTop">
                                <img src="assets/images/bar-graph.png" class="" alt="Icon">
                            </span>
                        </li>
                        <li class="shadowBlock">
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="flex-grow-1">
                                    <span class="f-15">EURUSD</span>
                                    <p class="mb-0 f-14 green">+0.76%</p>
                                </span>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">LTP</span>
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">Change%</span>
                                <p class="mb-0 f-14">30,640.00</p>
                            </span>
                            <span class="blockTop">
                                <img src="assets/images/bar-graph.png" class="" alt="Icon">
                            </span>
                        </li>
                        <li class="shadowBlock">
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="flex-grow-1">
                                    <span class="f-15">EURUSD</span>
                                    <p class="mb-0 f-14 green">+0.76%</p>
                                </span>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">LTP</span>
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">Change%</span>
                                <p class="mb-0 f-14">30,640.00</p>
                            </span>
                            <span class="blockTop">
                                <img src="assets/images/bar-graph.png" class="" alt="Icon">
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-4 space">
                <!-- <h6 class="mb-3 medium">Most Volatile Pair</h6> -->
                <div class="card-body responsiveScrollar mt-4 pt-3 p-0">
                    <ul class="moverList">
                        <li class="shadowBlock">
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="flex-grow-1">
                                    <span class="f-15">EURUSD</span>
                                    <p class="mb-0 f-14 green">+0.76%</p>
                                </span>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">LTP</span>
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">Change%</span>
                                <p class="mb-0 f-14">30,640.00</p>
                            </span>
                            <span class="blockTop">
                                <img src="assets/images/bar-graph.png" class="" alt="Icon">
                            </span>
                        </li>
                        <li class="shadowBlock">
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="flex-grow-1">
                                    <span class="f-15">EURUSD</span>
                                    <p class="mb-0 f-14 green">+0.76%</p>
                                </span>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">LTP</span>
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">Change%</span>
                                <p class="mb-0 f-14">30,640.00</p>
                            </span>
                            <span class="blockTop">
                                <img src="assets/images/bar-graph.png" class="" alt="Icon">
                            </span>
                        </li>
                        <li class="shadowBlock">
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="flex-grow-1">
                                    <span class="f-15">EURUSD</span>
                                    <p class="mb-0 f-14 green">+0.76%</p>
                                </span>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">LTP</span>
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <span class="f-12 lighttext">Change%</span>
                                <p class="mb-0 f-14">30,640.00</p>
                            </span>
                            <span class="blockTop">
                                <img src="assets/images/bar-graph.png" class="" alt="Icon">
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row" v-show="demoWatchlist == '2'">
            <div class="col-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-4">
                        <li class="breadcrumb-item"><a @click="demoWatchlist = '1'" href="javascript:void(0)" class="green">All Discussions</a></li>
                        <li class="breadcrumb-item active" aria-current="page">All Topic</li>
                    </ol>
                </nav>
            </div>
            <alldiscussions />
        </div>
    </div>
<div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content border-0">
      <div class="modal-header">
        <h4 class="modal-title medium" id="exampleModalLabel">Ask a public question</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Topic Title</label>
            <input type="text" class="form-control" id="recipient-name" placeholder="Product Des">
            <p class="small">Be specific and imagine you’re asking a question to another person</p>
          </div>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Tags</label>
            <input type="text" class="form-control" id="recipient-name" placeholder="Product Des">
            <p class="small">Add up to 5 tags to describe what your question is about</p>
          </div>
          <div class="form-group mb-3">
            <label for="message-text" class="col-form-label">Description</label>
            <textarea class="form-control textarea" placeholder="Enter min 500 characters or less" id="message-text"></textarea>
          </div>
        </form>
      </div>
      <div class="modalFooter">
        <a href="/register" class="grd_btn">Submit Topic</a>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import alldiscussions from "./all_discussions";
export default {
    data() {
        return {
            demoWatchlist: '1'
        }
    },    
    components: {
        alldiscussions
    }
}
</script>

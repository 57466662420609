<template>
    <div class="col-12">
        <div class="addTicker border-bottom pb-3 flex-wrap rtBtn mb-3">
            <h5 class="mb-0 d-inline-flex align-items-center"><a class="d-flex" href="javascript:void(0)"><vue-feather size="20px" class="me-2 green" type="arrow-left-circle"></vue-feather></a> Demo Watchlist </h5>
            <div class="d-flex align-items-center dWatch">
                <a href="javascript:void(0)" class="iconcolor gray_btn d-flex align-items-center"> Public <vue-feather class="ms-2" size="16px" type="check"></vue-feather></a>
                <a href="javascript:void(0)" class="mx-3 border-btn"> Add Ticker </a>
                <a href="javascript:void(0)" class="trash"> <vue-feather size="16px" type="trash"></vue-feather> </a>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between rtBtn mb-4">
           <div class="d-flex align-items-center">
                <p class="f-12 flex-shrink-0 mb-0 me-2">Short by</p>
                <select class="form-control form-select f-14">
                    <option>Ticker</option>
                    <option>Ticker 1</option>
                    <option>Ticker 2</option>
                </select>
            </div>
            <div class="d-flex align-items-center">
                <a @click.prevent="profileTab = 'listing'" :class="(profileTab == 'listing')?'active':''" href="javascript:void(0)" class="viewGrid me-2 maskImg"><span class="icon icon1"></span></a>
                <a @click.prevent="profileTab = 'grid'" :class="(profileTab == 'grid')?'active':''" href="javascript:void(0)" class="viewGrid maskImg"><span class="icon icon2"></span></a>
            </div>
        </div>
        <div class="row gridView" v-if="profileTab == 'grid'">
            <div class="col-12 col-lg-6">
                <div class="card-body myWatchList p-0">
                    <div class="p-3 d-flex align-items-center justify-content-between border-bottom pb-2">
                        <h6 class="mb-0">UBX</h6>
                        <div class="rtBtn d-flex align-items-center">
                           <p class="mb-0 me-3 red medium">-2.14</p>
                           <a class="d-flex" href="javascript:void(0)"> <vue-feather size="16px" type="trash"></vue-feather></a>
                        </div>
                    </div>
                    <div class="p-3 d-flex align-items-center justify-content-between">
                        <div class="block1">
                            <p class="mb-1 lighttext">Duration</p>
                            <h6>7 days</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Price</p>
                            <h6 class="red"><i class="fa fa-caret-down" aria-hidden="true"></i> $4.18</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Change</p>
                            <h6 class="red"><i class="fa fa-caret-down" aria-hidden="true"></i> 4.35%</h6>
                        </div>
                    </div>
                    <div class="chartContent">
                       <img class="d-block" src="assets/images/chart/redchart.svg" alt="Chart" title="Chart">
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card-body myWatchList p-0">
                    <div class="p-3 d-flex align-items-center justify-content-between border-bottom pb-2">
                        <h6 class="mb-0">UBX</h6>
                        <div class="rtBtn d-flex align-items-center">
                           <p class="mb-0 me-3 red medium">-2.14</p>
                           <a class="d-flex" href="javascript:void(0)"> <vue-feather size="16px" type="trash"></vue-feather></a>
                        </div>
                    </div>
                    <div class="p-3 d-flex align-items-center justify-content-between">
                        <div class="block1">
                            <p class="mb-1 lighttext">Duration</p>
                            <h6>7 days</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Price</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> $4.18</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Change</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> 4.35%</h6>
                        </div>
                    </div>
                    <div class="chartContent">
                       <img class="d-block" src="assets/images/chart/greenchart.svg" alt="Chart" title="Chart">
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card-body myWatchList p-0">
                    <div class="p-3 d-flex align-items-center justify-content-between border-bottom pb-2">
                        <h6 class="mb-0">UBX</h6>
                        <div class="rtBtn d-flex align-items-center">
                           <p class="mb-0 me-3 red medium">-2.14</p>
                           <a class="d-flex" href="javascript:void(0)"> <vue-feather size="16px" type="trash"></vue-feather></a>
                        </div>
                    </div>
                    <div class="p-3 d-flex align-items-center justify-content-between">
                        <div class="block1">
                            <p class="mb-1 lighttext">Duration</p>
                            <h6>7 days</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Price</p>
                            <h6 class="red"><i class="fa fa-caret-down" aria-hidden="true"></i> $4.18</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Change</p>
                            <h6 class="red"><i class="fa fa-caret-down" aria-hidden="true"></i> 4.35%</h6>
                        </div>
                    </div>
                    <div class="chartContent">
                       <img class="d-block" src="assets/images/chart/redchart.svg" alt="Chart" title="Chart">
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card-body myWatchList p-0">
                    <div class="p-3 d-flex align-items-center justify-content-between border-bottom pb-2">
                        <h6 class="mb-0">UBX</h6>
                        <div class="rtBtn d-flex align-items-center">
                           <p class="mb-0 me-3 red medium">-2.14</p>
                           <a class="d-flex" href="javascript:void(0)"> <vue-feather size="16px" type="trash"></vue-feather></a>
                        </div>
                    </div>
                    <div class="p-3 d-flex align-items-center justify-content-between">
                        <div class="block1">
                            <p class="mb-1 lighttext">Duration</p>
                            <h6>7 days</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Price</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> $4.18</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Change</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> 4.35%</h6>
                        </div>
                    </div>
                    <div class="chartContent">
                       <img class="d-block" src="assets/images/chart/greenchart.svg" alt="Chart" title="Chart">
                    </div>
                </div>
            </div>
        </div>
        <div class="row listView" v-if="profileTab == 'listing'">
            <div class="col-12">
                <div class="card-body myWatchList p-0">
                    <div class="p-3 d-flex align-items-center justify-content-between responsiveScroll">
                        <div class="block1">
                            <p class="mb-1 lighttext">Ticker</p>
                            <h6>UBX</h6>
                        </div> 
                        <div class="block1">
                            <p class="mb-1 lighttext">Duration</p>
                            <h6>7 days</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Price</p>
                            <h6 class="red"><i class="fa fa-caret-down" aria-hidden="true"></i> $4.18</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Change</p>
                            <h6 class="red"><i class="fa fa-caret-down" aria-hidden="true"></i> 4.35%</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Chart</p>
                            <img class="d-block" src="assets/images/chart/sm-red.svg" alt="Chart" title="Chart">
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Overall</p>
                            <h6 class="red">-2.14</h6>
                        </div>
                        <div class="block1">
                            <button class="trashCirle redtheme"> <vue-feather size="16px" type="trash"></vue-feather> </button>
                        </div>
                    </div>
                </div>
                <div class="card-body myWatchList p-0">
                    <div class="p-3 d-flex align-items-center justify-content-between responsiveScroll">
                        <div class="block1">
                            <p class="mb-1 lighttext">Ticker</p>
                            <h6>UBX</h6>
                        </div> 
                        <div class="block1">
                            <p class="mb-1 lighttext">Duration</p>
                            <h6>7 days</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Price</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> $4.18</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Change</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> 4.35%</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Chart</p>
                            <img class="d-block" src="assets/images/chart/sm-green.svg" alt="Chart" title="Chart">
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Overall</p>
                            <h6 class="green">2.14</h6>
                        </div>
                        <div class="block1">
                            <button class="trashCirle redtheme"> <vue-feather size="16px" type="trash"></vue-feather> </button>
                        </div>
                    </div>
                </div>
                <div class="card-body myWatchList p-0">
                    <div class="p-3 d-flex align-items-center justify-content-between responsiveScroll">
                        <div class="block1">
                            <p class="mb-1 lighttext">Ticker</p>
                            <h6>UBX</h6>
                        </div> 
                        <div class="block1">
                            <p class="mb-1 lighttext">Duration</p>
                            <h6>7 days</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Price</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> $4.18</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Change</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> 4.35%</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Chart</p>
                            <img class="d-block" src="assets/images/chart/sm-green.svg" alt="Chart" title="Chart">
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Overall</p>
                            <h6 class="green">2.14</h6>
                        </div>
                        <div class="block1">
                            <button class="trashCirle redtheme"> <vue-feather size="16px" type="trash"></vue-feather> </button>
                        </div>
                    </div>
                </div>
                <div class="card-body myWatchList p-0">
                    <div class="p-3 d-flex align-items-center justify-content-between responsiveScroll">
                        <div class="block1">
                            <p class="mb-1 lighttext">Ticker</p>
                            <h6>UBX</h6>
                        </div> 
                        <div class="block1">
                            <p class="mb-1 lighttext">Duration</p>
                            <h6>7 days</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Price</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> $4.18</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Change</p>
                            <h6 class="green"><i class="fa fa-caret-up" aria-hidden="true"></i> 4.35%</h6>
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Chart</p>
                            <img class="d-block" src="assets/images/chart/sm-green.svg" alt="Chart" title="Chart">
                        </div>
                        <div class="block1">
                            <p class="mb-1 lighttext">Overall</p>
                            <h6 class="green">2.14</h6>
                        </div>
                        <div class="block1">
                            <button class="trashCirle redtheme"> <vue-feather size="16px" type="trash"></vue-feather> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            profileTab: 'grid',
        }
    },    
}
</script>

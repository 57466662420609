<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="table-responsive snapTable">
                    <table class="table borderedTable">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Followers</th>
                              <!--   <th>Reputation</th> -->
                                <th>ideas</th>
                                <!-- <th align="right">Action</th> -->
                            </tr>
                        </thead>
                        <tbody v-if="store.userFollowers.length > 0">
                            <tr v-for="item,index in store.userFollowers">
                                <td>
                                    <span class="d-flex align-items-center">
                                        <!-- <span class="avtar me-4 active"><img src="assets/images/profile-user.svg" alt="User" title="Forexnoob"></span> -->
                                        <p class="mb-0 f-16 me-2 pointer" @click="gotoProfile(item.follower_id)">{{item.firstname}} {{item.lastname}}</p>
                                        <span class="avtarAward"><img src="assets/images/awardIcon.svg" alt="User" title="Award"></span>
                                    </span>
                                </td>
                                <td>{{item.userFollowers}}</td>
                                <!-- <td>10</td> -->
                                 <td>{{item.tradeIdeas || 0}}</td>
                                <!-- <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:void(0)" class="action-btn greenBtn active">Following</a>
                                        <a href="javascript:void(0)" class="action-btn"><vue-feather type="mail"></vue-feather></a>
                                        <span class="position-relative">
                                            <a @click="dropDown = !dropDown" href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                            <div class="dropdown_menu_animated" :class="dropDown?'show':''">
                                                <li><a href="javascript:void(0)">Remove</a></li>
                                                <li><a href="javascript:void(0)">Block</a></li>
                                            </div>
                                        </span>
                                    </span>
                                </td> -->
                            </tr>
                        </tbody>
                        <no-record  :type="'table'" :colspan="'3'" v-else></no-record>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from "@/store";
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            dropDown: false,
        }
    },  
    methods:{
        gotoProfile(id){
            if(id){
                this.$router.replace({
                    'path' : this.$route.path,
                    'query' : {'id' : id}
                })
            }
        },
        callUserFollowers(){
            let form = {
              "type":"followers"
            };
            this.store.getUserFollowers(form,true)
        }
    },
    created(){
        this.callUserFollowers()
    }  
}
</script>

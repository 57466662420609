<template>
    <div class="container">
        <div class="row space">
            <div class="col-12 col-xl-4 mb-4 mb-xl-0">
                <div class="d-flex align-items-center justify-content-between mb-4">
                    <h6 class="f-18 medium mb-0"> My Portfolio</h6>
                    <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" class="greenBtn text-center small">View All</a>
                </div>
                <div class="card-body shadowBlock p-0">
                    <div class="gradientBG d-flex align-items-center">
                        <div class="blockOne w-50 border-end">
                            <p class="mb-2">Invested:</p>
                            <h5 class="f-20 medium mb-0">$30,000.00</h5>
                        </div>
                         <div class="blockOne w-50">
                            <p class="mb-2">Net Profit:</p>
                            <h5 class="f-20 medium mb-0">$219.65<span class="f-14">(+0.76%)</span></h5>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-stripe">
                            <thead>
                                <tr>
                                    <th class="pl-5">Symbol</th>
                                    <th>LTP</th>
                                    <th>Change%</th>
                                    <th>Volume</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="d-flex align-items-center">
                                            <a class="d-inline-flex me-2" href="#"><img src="assets/images/market-flag1.png" alt="" /></a>
                                            <span>EURUSD</span>
                                        </span>
                                    </td>
                                    <td>14.750.45</td>
                                    <td class="green">+0.76%</td>
                                    <td>982.81K</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="d-flex align-items-center">
                                            <a class="d-inline-flex me-2" href="#"><img src="assets/images/market-flag1.png" alt="" /></a>
                                            <span>GBPJPY</span>
                                        </span>
                                    </td>
                                    <td>14.750.45</td>
                                    <td class="red">-0.76%</td>
                                    <td>982.81K</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="d-flex align-items-center">
                                            <a class="d-inline-flex me-2" href="#"><img src="assets/images/market-flag1.png" alt="" /></a>
                                            <span>GBPAUD</span>
                                        </span>
                                    </td>
                                    <td>14.750.45</td>
                                    <td class="green">+0.76%</td>
                                    <td>982.81K</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="d-flex align-items-center">
                                            <a class="d-inline-flex me-2" href="#"><img src="assets/images/market-flag1.png" alt="" /></a>
                                            <span>EURUSD</span>
                                        </span>
                                    </td>
                                    <td>14.750.45</td>
                                    <td class="green">+0.76%</td>
                                    <td>982.81K</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="d-flex align-items-center">
                                            <a class="d-inline-flex me-2" href="#"><img src="assets/images/market-flag1.png" alt="" /></a>
                                            <span>EURUSD</span>
                                        </span>
                                    </td>
                                    <td>14.750.45</td>
                                    <td class="red">-0.76%</td>
                                    <td>982.81K</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="d-flex align-items-center">
                                            <a class="d-inline-flex me-2" href="#"><img src="assets/images/market-flag1.png" alt="" /></a>
                                            <span>GBPJPY</span>
                                        </span>
                                    </td>
                                    <td>14.750.45</td>
                                    <td class="red">-0.76%</td>
                                    <td>982.81K</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="d-flex align-items-center">
                                            <a class="d-inline-flex me-2" href="#"><img src="assets/images/market-flag1.png" alt="" /></a>
                                            <span>GBPJPY</span>
                                        </span>
                                    </td>
                                    <td>14.750.45</td>
                                    <td class="green">+0.76%</td>
                                    <td>982.81K</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-4 mb-4 mb-xl-0">
                <h6 class="f-18 medium mb-4"> If i have Invested</h6>
                <div class="card-body shadowBlock p-0">
                    <div class="calculateAmount d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="blockOne me-1">
                                <select class="form-control form-select ps-0 border-0">
                                    <option>DOGEUSD</option>
                                    <option>EURUSD</option>
                                    <option>GBPAUD</option>
                                    <option>EURUSD</option>
                                </select>
                            </div>
                             <div class="blockOne">
                                <select class="form-control form-select border-0">
                                    <option>Others</option>
                                    <option>EURUSD</option>
                                    <option>GBPAUD</option>
                                    <option>EURUSD</option>
                                </select>
                            </div>
                        </div>
                         <div class="blockOne">
                           <a href="javascript:void(0)" class="greenBtn d-inline-flex align-items-center"><vue-feather type="plus" class="me-1" size="16px"></vue-feather>Add Symbol</a>
                        </div>
                    </div>
                    <div class="calculateAmount pt-0 d-flex align-items-center justify-content-between">
                        <div class="blocksnd me-1">
                            <label class="mb-1 f-12">Monthly Amount</label>
                            <input type="text" class="form-control" placeholder="&#8377; 1000" name="">
                        </div>
                        <div class="blocksnd">
                            <label class="mb-1 f-12">Monthly Amount</label>
                            <select class="form-control form-select">
                                <option>Weekly</option>
                                <option>Monthly</option>
                                <option>yrarly</option>
                            </select>
                        </div>
                         <div class="blocksnd">
                           <a href="javascript:void(0)" class="grd_btn">Calculate</a>
                        </div>
                    </div>
                    <div class="amountChart p-3">
                        <img src="assets/images/chart/chart-amount.svg" alt="Chart" title="If i have Invested">
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-4">
                <div class="d-flex align-items-center justify-content-between customMargin">
                    <h6 class="f-18 medium mb-0">
                        <select class="form-control form-select ps-0 border-0">
                            <option>Top movers</option>
                            <option>Top movers</option>
                            <option>Top movers</option>
                            <option>Top movers</option>
                        </select>
                    </h6>
                    <a href="javascript:void(0)" class="greenBtn text-center small">View All</a>
                </div>
                <div class="card-body p-0">
                    <ul class="moverList shadowBlock">
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                        <li>
                            <span class="d-flex align-items-center">
                                <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                <span class="f-15">EURUSD</span>
                            </span>
                            <span class="blockTop">
                                <p class="mb-0 f-14">1500</p>
                            </span>
                            <span class="blockTop">
                                <p class="d-flex align-items-center mb-0 f-14 greenBtn small"><vue-feather size="15" type="arrow-up"></vue-feather> +0.76%</p>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row space">
            <div class="col-12">
                <h6 class="f-18 medium mb-4">Buzzing Symbols</h6>
                <ul class="d-flex align-items-center flex-wrap buzzingSymbols">
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                            <span class="greenBtn"><vue-feather type="arrow-up"></vue-feather></span>
                            <span class="flex-grow-1 ms-2">
                                <span class="d-flex align-items-start">
                                    <img class="me-1 mt-1" width="19px" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                    <span>
                                        <span class="f-15 medium">EURUSD</span>
                                        <p class="mb-0 light f-12">30,640.00</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span class="content text-end ms-2">
                            <p class="mb-0 medium">+1.25%</p>
                            <p class="mb-0 light f-12">1500</p>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row space">
            <div class="col-12 col-lg-6 mb-4 mb-xl-0">
                <h6 class="f-18 medium mb-4">Related events</h6>
                <div class="card-body shadowBlock">
                    <ul class="newsList">
                        <li class="pb-3 border-bottom mb-3">
                            <h6 class="mb-1">US 10Y yields point to some consolidation around 1.28% - Commerzbank</h6>
                            <p class="mb-1 lighttext">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            <span class="lighttext f-12 d-flex align-items-center"><vue-feather size="12px" class="me-1" type="clock"></vue-feather> 7 hours ago </span>
                        </li>
                        <li class="pb-3 border-bottom mb-3">
                            <h6 class="mb-1">US 10Y yields point to some consolidation around 1.28% - Commerzbank</h6>
                            <p class="mb-1 lighttext">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            <span class="lighttext f-12 d-flex align-items-center"><vue-feather size="12px" class="me-1" type="clock"></vue-feather> 7 hours ago </span>
                        </li>
                        <li class="pb-3 border-bottom mb-3">
                            <h6 class="mb-1">US 10Y yields point to some consolidation around 1.28% - Commerzbank</h6>
                            <p class="mb-1 lighttext">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            <span class="lighttext f-12 d-flex align-items-center"><vue-feather size="12px" class="me-1" type="clock"></vue-feather> 7 hours ago </span>
                        </li>
                        <li class="pb-3 border-bottom mb-3">
                            <h6 class="mb-1">US 10Y yields point to some consolidation around 1.28% - Commerzbank</h6>
                            <p class="mb-1 lighttext">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            <span class="lighttext f-12 d-flex align-items-center"><vue-feather size="12px" class="me-1" type="clock"></vue-feather> 7 hours ago </span>
                        </li>
                        <li>
                            <h6 class="mb-1">US 10Y yields point to some consolidation around 1.28% - Commerzbank</h6>
                            <p class="mb-1 lighttext">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            <span class="lighttext f-12 d-flex align-items-center"><vue-feather size="12px" class="me-1" type="clock"></vue-feather> 7 hours ago </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="d-flex align-items-center justify-content-between  mb-4">
                    <h6 class="f-18 medium mb-0">Economic Events</h6>
                    <div class="sortBy d-flex align-items-center">
                        <p class="f-12 flex-shrink-0 mb-0 me-2">Short by</p>
                        <select class="form-control form-select">
                            <option>Weekly</option>
                            <option>Monthly</option>
                            <option>Yearly</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="eEvents">
                        <div class="table-responsive economic w-100 shadowBlock mb-4">
                            <table class="table table-sm border-0 bg-white">
                                <thead>
                                <tr>
                                    <th class="date medium">April 30</th>
                                    <th colspan="3" class="events text-end">21 Events </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="active borderAdd bt">
                                    <td class="active-td">
                                    <span class="d-flex align-items-center">
                                        <img src="assets/images/economic-flag1.svg" class="rounded-circle me-2" alt="">
                                        UK
                                    </span>
                                    </td>
                                    <td>01:00am</td>
                                    <td>Markit/CIPS Manufacturing PMI</td>
                                    <td><a href="#"><vue-feather type="chevron-down"></vue-feather></a></td>
                                </tr>
                                <tr class="subactive-tr">
                                    <td colspan="4">
                                    <div class="d-flex align-items-center justify-content-between openDom">
                                        <span class="subactive-td">
                                        <div class="f-16">00:10:45</div>
                                        <div class="f-12">Time 18:45</div>
                                        </span>
                                        <span align="center" class="active-time-int middle">Actual --.--</span>
                                        <span colspan="2" class="subactive-td2">
                                        <div class="forecast">
                                        <span class="f-12 me-2">Forecast</span>
                                        <span class="f-16">60.70</span>
                                        </div>
                                        <div class="previe">
                                            <span class="f-12 me-2">Previous</span>
                                            <span class="f-16">58.70</span>
                                        </div>        
                                        </span>
                                    </div>
                                    </td>
                                </tr>
                                <tr class="borderAdd et">
                                    <td class="active-td2">
                                    <span class="d-flex align-items-center">
                                        <img src="assets/images/economic-flag1.svg" class="rounded-circle me-2" alt="">
                                        UK
                                    </span>
                                    </td>
                                    <td>10:00am</td>
                                    <td>GfK Consumer Confidence (May)</td>
                                    <td><a href="#"><vue-feather type="chevron-down"></vue-feather></a></td>
                                </tr>
                                <tr class="borderAdd rt">
                                    <td class="active-td3">
                                    <span class="d-flex align-items-center">
                                        <img src="assets/images/economic-flag2.svg" class="rounded-circle me-2" alt="">
                                        GER
                                    </span>
                                    </td>
                                    <td>02:00am</td>
                                    <td>Inflation Rate YoY (Apr)</td>
                                    <td><a href="#"><vue-feather type="chevron-down"></vue-feather></a></td>
                                </tr>
                                <tr class="borderAdd bt">
                                    <td class="active-td">
                                    <span class="d-flex align-items-center">
                                        <img src="assets/images/economic-flag3.svg" class="rounded-circle me-2" alt="">
                                        CAN
                                    </span>
                                    </td>
                                    <td>05:00am</td>
                                    <td>Fed Interest Rate Decision</td>
                                    <td><a href="#"><vue-feather type="chevron-down"></vue-feather></a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="card-body shadowBlock mb-4">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <p class="mb-0 f-13">June 01</p>
                                        <p class="mb-0 f-13">15 Events</p>
                                    </div> 
                                    <div class="content">
                                        <span class="f-12 medium d-flex align-items-center">
                                            <img src="assets/images/economic-flag1.svg" class="rounded-circle me-2" alt="">
                                            EURUSD
                                        </span>
                                        <p class="mb-0 f-12">Markit/CIPS Manufacturing PMI Final...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card-body shadowBlock mb-4">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <p class="mb-0 f-13">June 01</p>
                                        <p class="mb-0 f-13">15 Events</p>
                                    </div> 
                                    <div class="content">
                                        <span class="f-12 medium d-flex align-items-center">
                                            <img src="assets/images/economic-flag1.svg" class="rounded-circle me-2" alt="">
                                            EURUSD
                                        </span>
                                        <p class="mb-0 f-12">Markit/CIPS Manufacturing PMI Final...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card-body shadowBlock">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <p class="mb-0 f-13">June 01</p>
                                        <p class="mb-0 f-13">15 Events</p>
                                    </div> 
                                    <div class="content">
                                        <span class="f-12 medium d-flex align-items-center">
                                            <img src="assets/images/economic-flag1.svg" class="rounded-circle me-2" alt="">
                                            EURUSD
                                        </span>
                                        <p class="mb-0 f-12">Markit/CIPS Manufacturing PMI Final...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card-body shadowBlock">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <p class="mb-0 f-13">June 01</p>
                                        <p class="mb-0 f-13">15 Events</p>
                                    </div> 
                                    <div class="content">
                                        <span class="f-12 medium d-flex align-items-center">
                                            <img src="assets/images/economic-flag1.svg" class="rounded-circle me-2" alt="">
                                            EURUSD
                                        </span>
                                        <p class="mb-0 f-12">Markit/CIPS Manufacturing PMI Final...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rotateCarousel shadowBlock">
                        <ul class="selectMonth h-100">
                            <li><a href="javascript:void(0)">September</a></li>
                            <li><a href="javascript:void(0)">August</a></li>
                            <li><a href="javascript:void(0)">July</a></li>
                            <li><a href="javascript:void(0)">June</a></li>
                            <li><a href="javascript:void(0)">May</a></li>
                            <li><a href="javascript:void(0)">April</a></li>
                        </ul>
                        <div class="carouselArrow">
                            <a href="#" class="next"><vue-feather type="chevron-up"></vue-feather> </a>
                            <a href="#" class="prev"><vue-feather type="chevron-down"></vue-feather> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 col-xl-4 mb-4 mb-xl-0">
                <div class="d-flex align-items-center justify-content-between mb-4">
                    <h6 class="f-18 medium mb-0">Current Plan & subscriptions</h6>
                    <a href="javascript:void(0)" class="greenBtn text-center small">More Plans</a>
                </div>
                <div class="card-body gradient shadowBlock position-relative">
                    <h5 class="f-18">Power Investor Pack (Active)</h5>
                    <p class="mb-4">We are pleased to bring you internationally research on 4000+ companies</p>
                    <a href="javascript:void(0)" class="btn green f-14 bg-white mb-2 medium">Upgrade Plan</a>
                    <img class="packImg" src="assets/images/pack1.svg" alt="Image" title="Current Plan & subscriptions">
                </div>
            </div>
            <div class="col-12 col-xl-4">
                <div class="d-flex align-items-center justify-content-between mb-4">
                    <h6 class="f-18 medium mb-0">My offers</h6>
                    <div class="slideErrow d-flex align-items-center">
                        <a href="javascript:void(0)" class="arrowSlide me-2"><vue-feather size="10px" type="chevron-left"></vue-feather></a>
                        <a href="javascript:void(0)" class="arrowSlide active"><vue-feather size="10px" type="chevron-right"></vue-feather></a>
                    </div>
                </div>
                <div class="card-body gradient snd shadowBlock position-relative">
                    <h5 class="f-18">Apply online and get upto ₹459 cashback</h5>
                    <p class="mb-4">Get exciting offers and cashbacks on online application of personal loans</p>
                    <a href="javascript:void(0)" class="btn green f-14 bg-white mb-2 medium">Get it now</a>
                    <img class="packImg" src="assets/images/pack2.svg" alt="Image" title="Current Plan & subscriptions">
                </div>
            </div>
        </div> -->
    </div>
    <div class="modal fade viewMyPortfolio" id="staticBackdrop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                <button type="button" class="btn-close modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="d-flex align-items-center justify-content-between mb-4"> 
                    <div class="d-flex align-items-center"> 
                        <div class="chartProgress">
                            <label>Forex</label>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
                            </div>
                            <label class="mt-4">Crypto</label>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                            </div>
                        </div>
                        <span class="profileChart mx-4">
                            <img width="130" src="assets/images/chart/profilechart.svg" alt="Chart" title="Profile Chart">
                        </span>
                        <div class="chartProgress">
                            <label>Indices</label>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
                            </div>
                            <label class="mt-4">Currencies</label>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                            </div>
                        </div>
                    </div>
                    <div class="rightNetProfit">
                        <p class="f-16 medium mb-0">Investment <span class="green semibold large ms-4">$80,000</span></p>
                        <p class="f-16 medium mb-0">Net Profit <span class="f-15 ms-4">$240 <span class="green">+0.8%</span></span></p>
                    </div>
                </div>
                <div class="table-responsive border">
                    <table class="table bg-white proTable">
                        <thead>
                            <th>Symbol</th>
                            <th>Direction</th>
                            <th>QTY</th>
                            <th>Price</th>
                            <th>LTP</th>
                            <th>Today</th>
                            <th>Overall</th>
                            <th>Amount</th>
                            <th>invested  on</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span class="p-0 d-flex align-items-center"><img width="25" height="25" src="assets/images/country_flag/united states.webp" class="rounded-circle me-2" alt=""><span class="ellipsis">United States</span></span>
                                </td>
                                <td class="green">Buy</td>
                                <td>2</td>
                                <td>1.1805</td>
                                <td>1.1805</td>
                                <td class="green">+185 (+0.6%)</td>
                                <td>+640 (+2.19%)</td>
                                <td>$210015</td>
                                <td>29-07-2022</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="p-0 d-flex align-items-center"><img width="25" height="25" src="assets/images/country_flag/united states.webp" class="rounded-circle me-2" alt=""><span class="ellipsis">United States</span></span>
                                </td>
                                <td class="green">Buy</td>
                                <td>2</td>
                                <td>1.1805</td>
                                <td>1.1805</td>
                                <td class="green">+185 (+0.6%)</td>
                                <td>+640 (+2.19%)</td>
                                <td>$210015</td>
                                <td>29-07-2022</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="p-0 d-flex align-items-center"><img width="25" height="25" src="assets/images/country_flag/united states.webp" class="rounded-circle me-2" alt=""><span class="ellipsis">United States</span></span>
                                </td>
                                <td class="green">Buy</td>
                                <td>2</td>
                                <td>1.1805</td>
                                <td>1.1805</td>
                                <td class="green">+185 (+0.6%)</td>
                                <td>+640 (+2.19%)</td>
                                <td>$210015</td>
                                <td>29-07-2022</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="p-0 d-flex align-items-center"><img width="25" height="25" src="assets/images/country_flag/united states.webp" class="rounded-circle me-2" alt=""><span class="ellipsis">United States</span></span>
                                </td>
                                <td class="green">Buy</td>
                                <td>2</td>
                                <td>1.1805</td>
                                <td>1.1805</td>
                                <td class="green">+185 (+0.6%)</td>
                                <td>+640 (+2.19%)</td>
                                <td>$210015</td>
                                <td>29-07-2022</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="p-0 d-flex align-items-center"><img width="25" height="25" src="assets/images/country_flag/united states.webp" class="rounded-circle me-2" alt=""><span class="ellipsis">United States</span></span>
                                </td>
                                <td class="green">Buy</td>
                                <td>2</td>
                                <td>1.1805</td>
                                <td>1.1805</td>
                                <td class="green">+185 (+0.6%)</td>
                                <td>+640 (+2.19%)</td>
                                <td>$210015</td>
                                <td>29-07-2022</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },    
}
</script>

<template>
    <div class="settingPage d-flex align-items-start h-100">
        <div class="card-body customMax p-0">
            <ul class="settingTabs mb-0">
                <li><a @click.prevent="profileTab = 'Social'" :class="(profileTab == 'Social')?'active':''" href="javascript:void(0)">Social Accounts</a></li>
                <li><a @click.prevent="profileTab = 'Sharing'" :class="(profileTab == 'Sharing')?'active':''" href="javascript:void(0)">Restrict Sharing</a></li>
            </ul>
        </div>
        <div class="card-body rightView">
            <div class="showTabs" v-if="profileTab == 'Social'"> 
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <p class="mb-0 d-inline-flex align-items-center medium"><img class="me-3" src="assets/images/brands_twitter.svg" alt="Icon" title="Twitter"> Twitter</p> 
                    <a href="#" class="grd_btn small">Connect</a>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <p class="mb-0 d-inline-flex align-items-center medium"><img class="me-3" src="assets/images/whatsapp.svg" alt="Icon" title="Twitter">Whatsapp</p> 
                    <a href="#" class="grd_btn small">Connect</a>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <p class="mb-0 d-inline-flex align-items-center medium"><img class="me-3" src="assets/images/telegram.svg" alt="Icon" title="Twitter"> Telegram</p> 
                    <a href="#" class="grd_btn small">Connect</a>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <p class="mb-0 d-inline-flex align-items-center medium"><img class="me-3" src="assets/images/facebook-social.svg" alt="Icon" title="Twitter">Facebook</p> 
                    <a href="#" class="grd_btn small">Connect</a>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <p class="mb-0 d-inline-flex align-items-center medium"><img class="me-3" src="assets/images/reddit.svg" alt="Icon" title="Twitter">Reddit</p> 
                    <a href="#" class="grd_btn small">Connect</a>
                </div>
            </div>
            <div class="showTabs" v-if="profileTab == 'Sharing'">
                <div class="content pb-4">
                    <h6 class="mb-0">News</h6> 
                    <p class="mb-0">Select the channels you want to use to share your articles</p> 
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <h6 class="mb-0">Twitter</h6> 
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <h6 class="mb-0">Whatsapp</h6> 
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <h6 class="mb-0">Telegram</h6> 
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="content pb-4">
                    <h6 class="mb-1">Trade Ideas</h6> 
                    <p class="mb-0">Select the channels you want to use to share your articles</p> 
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <h6 class="mb-0">Twitter</h6> 
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <h6 class="mb-0">Whatsapp</h6> 
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <h6 class="mb-0">Telegram</h6> 
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            profileTab:'Social'
        }
    },    
}
</script>

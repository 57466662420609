<template>
    <div class="container space-footer">
        <div class="row">
            <div class="col-12 mb-4">
                <ul class="toplinks pb-2 d-flex align-items-center border-bottom">
                    <li class="ps-0"><a @click.prevent="profileTab = 'Articles'" :class="(profileTab == 'Articles')?'active':''" href="javascript:void(0)">Articles</a></li>
                    <li><a @click.prevent="profileTab = 'Ideas'" :class="(profileTab == 'Ideas')?'active':''" href="javascript:void(0)"> Ideas</a></li>
                    <li><a @click.prevent="profileTab = 'Insights'" :class="(profileTab == 'Insights')?'active':''" href="javascript:void(0)"> Research Insights</a></li>
                    <li><a @click.prevent="profileTab = 'Instruments'" :class="(profileTab == 'Instruments')?'active':''" href="javascript:void(0)">Followed Instruments</a></li>
                </ul>
            </div>
            <div class="alertTabShaow" v-if="profileTab == 'Articles'">
                <div class="table-responsive snapTable">
                    <table class="table borderedTable w-30">
                        <tbody>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>EUR/USD climbs to one-week high, around mid-1.1300s post-ECB decision</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-center">
                                        <img width="40" class="me-2" src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                        <span>Ivanna</span>
                                    </span>
                                </td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <p class="mb-0 me-4 lighttext">22 Dec 2021   |   04:42 PM IST</p>
                                        <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="trash"></vue-feather></a>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="alertTabShaow" v-if="profileTab == 'Ideas'">
                <div class="noData text-center">
                    <img class="mb-3" src="assets/images/underprocess.svg" alt="Icon" title="No data found">
                    <p class="mb-4">This page is under process</p>
                </div>
            </div>
            <div class="alertTabShaow" v-if="profileTab == 'Insights'">
                <div class="noData text-center">
                    <img class="mb-3" src="assets/images/underprocess.svg" alt="Icon" title="No data found">
                    <p class="mb-4">This page is under process</p>
                </div>
            </div>
            <div class="alertTabShaow" v-if="profileTab == 'Instruments'">
                <div class="noData text-center">
                    <img class="mb-3" src="assets/images/underprocess.svg" alt="Icon" title="No data found">
                    <p class="mb-4">This page is under process</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            profileTab: 'Articles',
            createAlert : '1',
            dropDown: false
        }
    },    
}
</script>

<template>
    <div class="col-12 responsiveScrollar" v-if="!investment">
        <div @click="investment = !investment" class="pointer shadowBlock d-flex align-items-start justify-content-between mb-3 py-2 px-3">
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Topic name</p>
                <h6 class="f-14 mb-0">Investment Learning</h6>
            </div>
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Views</p>
                <h6 class="f-14 mb-0">100K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Replies</p>
                <h6 class="f-14 mb-0">12K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Activity</p>
                <h6 class="f-14 mb-0">Today, 12:41 PM</h6>
            </div>
            <div class="block1">
                <p class="mb-0 lighttext">Users</p>
                <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
            </div>
        </div>
        <div class="pointer shadowBlock d-flex align-items-start justify-content-between mb-3 py-2 px-3">
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Topic name</p>
                <h6 class="f-14 mb-0">Investment Learning</h6>
            </div>
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Views</p>
                <h6 class="f-14 mb-0">100K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Replies</p>
                <h6 class="f-14 mb-0">12K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Activity</p>
                <h6 class="f-14 mb-0">Today, 12:41 PM</h6>
            </div>
            <div class="block1">
                <p class="mb-0 lighttext">Users</p>
                <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
            </div>
        </div>
        <div class="pointer shadowBlock d-flex align-items-start justify-content-between mb-3 py-2 px-3">
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Topic name</p>
                <h6 class="f-14 mb-0">Investment Learning</h6>
            </div>
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Views</p>
                <h6 class="f-14 mb-0">100K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Replies</p>
                <h6 class="f-14 mb-0">12K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Activity</p>
                <h6 class="f-14 mb-0">Today, 12:41 PM</h6>
            </div>
            <div class="block1">
                <p class="mb-0 lighttext">Users</p>
                <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
            </div>
        </div>
        <div class="pointer shadowBlock d-flex align-items-start justify-content-between mb-3 py-2 px-3">
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Topic name</p>
                <h6 class="f-14 mb-0">Investment Learning</h6>
            </div>
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Views</p>
                <h6 class="f-14 mb-0">100K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Replies</p>
                <h6 class="f-14 mb-0">12K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Activity</p>
                <h6 class="f-14 mb-0">Today, 12:41 PM</h6>
            </div>
            <div class="block1">
                <p class="mb-0 lighttext">Users</p>
                <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
            </div>
        </div>
        <div class="pointer shadowBlock d-flex align-items-start justify-content-between mb-3 py-2 px-3">
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Topic name</p>
                <h6 class="f-14 mb-0">Investment Learning</h6>
            </div>
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Views</p>
                <h6 class="f-14 mb-0">100K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Replies</p>
                <h6 class="f-14 mb-0">12K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Activity</p>
                <h6 class="f-14 mb-0">Today, 12:41 PM</h6>
            </div>
            <div class="block1">
                <p class="mb-0 lighttext">Users</p>
                <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
            </div>
        </div>
        <div class="pointer shadowBlock d-flex align-items-start justify-content-between mb-3 py-2 px-3">
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Topic name</p>
                <h6 class="f-14 mb-0">Investment Learning</h6>
            </div>
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Views</p>
                <h6 class="f-14 mb-0">100K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Replies</p>
                <h6 class="f-14 mb-0">12K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Activity</p>
                <h6 class="f-14 mb-0">Today, 12:41 PM</h6>
            </div>
            <div class="block1">
                <p class="mb-0 lighttext">Users</p>
                <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
            </div>
        </div>
        <div class="pointer shadowBlock d-flex align-items-start justify-content-between mb-3 py-2 px-3">
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Topic name</p>
                <h6 class="f-14 mb-0">Investment Learning</h6>
            </div>
            <div class="block1">
                <p class="mb-0 f-12 lighttext">Views</p>
                <h6 class="f-14 mb-0">100K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Replies</p>
                <h6 class="f-14 mb-0">12K</h6>
            </div>
            <div class="block1">
                <p class="mb-1 f-12 lighttext">Activity</p>
                <h6 class="f-14 mb-0">Today, 12:41 PM</h6>
            </div>
            <div class="block1">
                <p class="mb-0 lighttext">Users</p>
                <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
            </div>
        </div>
    </div>
    <div class="row" v-if="investment">
        <investment />
    </div>
</template>

<script>
import investment from "./investment_learning";
export default {
    data() {
        return {
            demoWatchlist: '1',
            investment: false,
        }
    },
    components: {
        investment
    }    
}
</script>

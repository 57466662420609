<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="noData text-center">
                    <img class="mb-3" src="assets/images/nodata.svg" alt="Icon" title="No data found">
                    <p>User has no strategies yet.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },    
}
</script>

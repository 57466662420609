<template>
    <div class="col-12">
        <div class="main-title border-bottom trade mb-4">
            <h3>Investment Learning</h3>
            <div class="d-flex align-items-center">
                <p class="small me-3">Asked <span class="medium">2 days ago</span></p>
                <p class="small">Viewed <span class="medium">100K</span></p>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-8">
        <div class="commentsPanel">
            <div class="d-flex mb-4">
                <div class="flex-shrink-0">
                    <img src="assets/images/comment-1.png" alt="Profile Picture" title="Arlene McCoy">
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1 medium">Arlene McCoy</h6>
                    <p class="lighttext">2 days ago</p>
                    <p>Hi, i have a question regarding purchase of same stocks (different quantities) in both NSE and BSE. Example if i purchase 5 stocks of Apollotyres in NSE @100 and then purchasing 5 in BSE @90, will my portfolio display apollotyres twice one with BSE and another with NSE with respective averages?</p>
                    <div class="d-flex align-items-center replies">
                        <p class="mb-0 d-inline-flex align-items-center medium me-4"><vue-feather class="me-1 greenBG" type="heart"></vue-feather> 4 Likes</p>
                        <p class="mb-0 d-inline-flex align-items-center medium"><vue-feather class="me-1" size="19px" type="message-circle"></vue-feather> 2 Replies</p>
                        <!-- <p class="mb-0 d-inline-flex align-items-center"><vue-feather size="19px" type="heart"></vue-feather> 4 Likes</p> -->
                    </div>
                </div>
            </div>
            <div class="d-flex mb-4 replyComment">
                <div class="flex-shrink-0">
                    <img src="assets/images/comment-1.png" alt="Profile Picture" title="Arlene McCoy">
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1 medium">Arlene McCoy</h6>
                    <p class="lighttext">2 days ago</p>
                    <p>Hi, i have a question regarding purchase of same stocks (different quantities) in both NSE and BSE. Example if i purchase 5 stocks of Apollotyres in NSE @100 and then purchasing 5 in BSE @90, will my portfolio display apollotyres twice one with BSE and another with NSE with respective averages?</p>
                    <div class="d-flex align-items-center replies">
                        <p class="mb-0 d-inline-flex align-items-center medium me-4"><vue-feather class="me-1 greenBG" type="heart"></vue-feather> 4 Likes</p>
                        <p class="mb-0 d-inline-flex align-items-center medium"><vue-feather class="me-1" size="19px" type="message-circle"></vue-feather> 2 Replies</p>
                        <!-- <p class="mb-0 d-inline-flex align-items-center"><vue-feather size="19px" type="heart"></vue-feather> 4 Likes</p> -->
                    </div>
                </div>
            </div>
            <div class="d-flex mb-4 replyComment inner">
                <div class="flex-shrink-0">
                    <img src="assets/images/comment-1.png" alt="Profile Picture" title="Arlene McCoy">
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1 medium">Arlene McCoy</h6>
                    <p class="lighttext">2 days ago</p>
                    <p>Hi, i have a question regarding purchase of same stocks (different quantities) in both NSE and BSE. Example if i purchase 5 stocks of Apollotyres in NSE @100 and then purchasing 5 in BSE @90, will my portfolio display apollotyres twice one with BSE and another with NSE with respective averages?</p>
                    <div class="d-flex align-items-center replies">
                        <p class="mb-0 d-inline-flex align-items-center medium me-4"><vue-feather class="me-1 greenBG" type="heart"></vue-feather> 4 Likes</p>
                        <p class="mb-0 d-inline-flex align-items-center medium"><vue-feather class="me-1" size="19px" type="message-circle"></vue-feather> 2 Replies</p>
                        <!-- <p class="mb-0 d-inline-flex align-items-center"><vue-feather size="19px" type="heart"></vue-feather> 4 Likes</p> -->
                    </div>
                </div>
            </div>
            <div class="commentBox form-group">
                <textarea class="form-control textarea mb-3" placeholder="Post a comment"></textarea>
                <div class="d-flex align-items-center justify-content-end">
                    <a href="javascript:void(0)" class="border-btn green me-2">Cancel</a>
                    <a href="javascript:void(0)" class="grd_btn">Submit</a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-4">
        <h5 class="mb-4">Related Topics</h5>
        <div class="card-body myWatchList">
            <h6>Investment Learning</h6>
            <div class="d-flex align-items-start justify-content-between border-bottom mb-2 pb-2">
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Views</p>
                    <h6 class="f-14">100K</h6>
                </div>
                <div class="block1">
                    <p class="mb-1 lighttext">Users</p>
                    <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
                </div>
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Replies</p>
                    <h6 class="f-14">12K</h6>
                </div>
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Activity</p>
                    <h6 class="f-14">Today, 12:41 PM</h6>
                </div>
            </div>
            <div class="singleLine">
                <div class="block1">
                    <p class="mb-0 lighttext f-12">Last message</p>
                    <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                </div>
            </div>
        </div>
        <div class="card-body myWatchList">
            <h6>Investment Learning</h6>
            <div class="d-flex align-items-start justify-content-between border-bottom mb-2 pb-2">
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Views</p>
                    <h6 class="f-14">100K</h6>
                </div>
                <div class="block1">
                    <p class="mb-1 lighttext">Users</p>
                    <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
                </div>
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Replies</p>
                    <h6 class="f-14">12K</h6>
                </div>
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Activity</p>
                    <h6 class="f-14">Today, 12:41 PM</h6>
                </div>
            </div>
            <div class="singleLine">
                <div class="block1">
                    <p class="mb-0 lighttext f-12">Last message</p>
                    <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                </div>
            </div>
        </div>
        <div class="card-body myWatchList">
            <h6>Investment Learning</h6>
            <div class="d-flex align-items-start justify-content-between border-bottom mb-2 pb-2">
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Views</p>
                    <h6 class="f-14">100K</h6>
                </div>
                <div class="block1">
                    <p class="mb-1 lighttext">Users</p>
                    <img class="d-block" src="assets/images/multi.png" alt="Chart" title="Chart">
                </div>
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Replies</p>
                    <h6 class="f-14">12K</h6>
                </div>
                <div class="block1">
                    <p class="mb-1 f-12 lighttext">Activity</p>
                    <h6 class="f-14">Today, 12:41 PM</h6>
                </div>
            </div>
            <div class="singleLine">
                <div class="block1">
                    <p class="mb-0 lighttext f-12">Last message</p>
                    <p class="mb-0 f-14">2nd Level Learning beyond Investing Basics and Stock Market..</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            demoWatchlist: '1'
        }
    },    
}
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="pb-2 d-flex align-items-center border-bottom justify-content-between profileIdea">
                    <ul class="toplinks mb-0">
                        <li class="ps-0"><a class="active" href="javascript:void(0)"> All</a></li>
                        <!--  <li><a href="javascript:void(0)"> Posted</a></li>
                    <li><a href="javascript:void(0)"> Liked</a></li>
                    <li><a href="javascript:void(0)"> Commented</a></li>
                    <li><a href="javascript:void(0)"> Following</a></li>
                    <li><a href="javascript:void(0)"> Reposted</a></li> -->
                    </ul>
                    <div class="portIdea d-flex mobWrap justify-content-end">
                        <div class="filterRemove">
                            <span v-if="symbol">{{symbol}}<a href="javascript:void(0)"
                                    @click="symbol = '';assetFilter = '';position = '';timeInterval = '';callTradeIdeas()">
                                    <vue-feather type="x"></vue-feather>
                                </a></span>
                            <span v-if="classification">{{classification}}<a href="javascript:void(0)"
                                    @click="classification = '';assetFilter = '';position = '';timeInterval = '';callTradeIdeas()">
                                    <vue-feather type="x"></vue-feather>
                                </a></span>
                            <span v-if="type">{{type}}<a href="javascript:void(0)"
                                    @click="type = '';assetFilter = '';position = '';timeInterval = '';callTradeIdeas()">
                                    <vue-feather type="x"></vue-feather>
                                </a></span>
                        </div>
                        <div class="grphBtn mobWrap transparent position-relative">

                            <select class="form-control form-select border-select me-md-2 f-12 smallBtn"
                                v-model="assetFilter"
                                @change="showCalendar = false;date = '';mostReadIdeas = false;page=1;callTradeIdeas()">
                                <option value=''>All</option>
                                <option v-for="item,index in static_vars.tradeIdeasFilter" :key="index" :value="item">
                                    {{item}}</option>
                            </select>
                            <select class="form-control form-select border-select me-md-2 f-12 smallBtn"
                                v-model="position"
                                @change="showCalendar = false;date = '';mostReadIdeas = false;page=1;callTradeIdeas()">
                                <option value=''>All</option>
                                <option v-for="item,index in positions" :key="index" :value="item">{{item}}
                                </option>
                            </select>
                            <select class="form-control form-select border-select me-md-2 f-12 smallBtn"
                                v-model="timeInterval"
                                @change="showCalendar = false;date = '';mostReadIdeas = false;page=1;callTradeIdeas()">
                                <option value=''>All</option>
                                <option v-for="item,index in intervalTitle" :key="index" :value="index">{{item}}
                                </option>
                            </select>

                            <!-- <a href="javascript:void(0)"><vue-feather type="clock"></vue-feather> </a> -->
                            <a href="javascript:void(0)"
                                @click="mostReadIdeas = !mostReadIdeas;assetFilter = '';position = '';timeInterval = '';showCalendar = false;date = '';page=1;callTradeIdeas()"><img
                                    src="assets/images/fire.svg" alt="Grid" title="Trending">
                            </a>
                            <Datepicker :range="true" v-model="date" :multiCalendars="true" inline autoApply
                                :multiCalendarsSolo="true" :enableTimePicker="false" ref="datepicker"
                                :maxDate="new Date()" v-if="showCalendar" @update:modelValue="handleDate" />
                            <a class="me-0 filter-dropdown" href="javascript:void(0)"
                                @click="date = '';showCalendar = !showCalendar;assetFilter = '';position = '';timeInterval = '';mostReadIdeas = false"
                                v-if="!showCalendar">
                                <vue-feather type="calendar"></vue-feather>
                            </a>
                            <a class="me-0" href="javascript:void(0)" @click="date = '';showCalendar = false" v-else>
                                <vue-feather type="x"></vue-feather>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mob-scroll-content py-3 py-xl-0" v-if="store.tradeIdeasList.totalRecords">
            <div class="col-12 col-lg-4 mb-4" v-for="item,key in store.tradeIdeasList.records">
                <div class="card border-0 midNew h-100" :id="'trade'+item.trade_id">
                    <div class="card-body pointer spaceBetween">
                        <div class="upText">
                            <h4 class="card-title">
                                <a href="javascript:void(0)" @click=" () => goToDetailPage(item)">{{item.title}}</a>
                                <span class="modifyDate">{{formatDate(item.created)}}</span>
                            </h4>
                            <img :src="imageLink+'/get/doc?type=TRADE&doc='+item.document+'&id='+item.user_id"
                                class="img-responsive" alt="">
                            <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                                <div class="forexnood d-flex align-items-center">
                                    <p class="IdeaUsername mb-0">{{item.firstname.charAt(0) ||
                                    ''}}{{item.lastname.charAt(0) || ''}}</p> <span class="mx-2">{{item.firstname ||
                                        ''}} {{item.lastname || ''}}</span>
                                    <!--  <img src="assets/images/badge.jpg" alt=""> -->
                                </div>
                                <div class="currency-wrap">
                                    <span class="currency"><a href="javascript:void(0)"
                                            @click="symbol = item.symbol;callTradeIdeas()">{{item.symbol ||
                                            'N/A'}}</a></span>
                                    <span class="monthly"><a href="javascript:void(0)"
                                            @click="timeInterval = item.time_interval;callTradeIdeas()">{{item.time_interval
                                            || 'N/A'}}</a></span>
                                    <span class="short"><a href="javascript:void(0)"
                                            :class="SymbolClass[item.position.toLowerCase()]"
                                            @click="position = item.position;callTradeIdeas()"><i
                                                data-feather="arrow-down-right"></i> {{item.position ||
                                                'N/A'}}</a></span>
                                </div>
                            </div>
                            <p>{{item.description}}</p>
                        </div>
                        <div class="downText">
                            <div class="smallbtn d-flex align-items-center">
                                <span class="cardEelement"
                                    @click="classification= item.classification;callTradeIdeas()">{{item.classification
                                    || 'N/A'}}</span>
                                <span class="cardEelement" @click="type= item.type;callTradeIdeas()">{{item.type ||
                                'N/A'}}</span>
                            </div>
                        </div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div @click="() => likeIdea(item,'like')">
                                <span><i :class="parseInt(item.is_like) == 0 ? 'fa fa-heart-o' : 'fa fa-heart'"
                                        aria-hidden="true"></i>{{item.likes_count || 0}}</span>
                            </div>
                            <div class="righticons">
                                <span @click="() => likeIdea(item,'comment')"><i class="fa fa-comments-o"
                                        aria-hidden="true"></i> {{item.comment_count || 0}}</span>
                                <span><i class="fa fa-share-alt" aria-hidden="true"></i> {{item.share_count ||
                                0}}</span>
                                <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> {{item.bookmarks_count ||
                                0}}s</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center mt-4"
                v-if="store.tradeIdeasList.records.length < store.tradeIdeasList.totalRecords">
                <a href="javascript:void(0)" class="grd_btn" @click="loadMore()"> Load More</a>
            </div>
        </div>
        <no-record v-else></no-record>
        <login v-if="showPopup"></login>
    </div>
</template>

<script>
import { useMainStore } from "@/store";
import moment from 'moment'
import { Form, Field, ErrorMessage } from "vee-validate";
import login from "../../../components/shared/userlogin"
import $ from "jquery";
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            page: 1,
            perPage: 6,
            imageLink: process.env.VUE_APP_API_BASE_URL,
            showPopup: '',
            date: '',
            showCalendar: false,
            from: '',
            to: '',
            intervalTitle: {
                "1M": "1 Minute",
                "5M": "5 Minute",
                "15M": "15 Minute",
                "1H": "1 Hour",
                "4H": "4 Hour",
                "1D": "1 Day",
                "1W": "1 Week",
                "1MN": "1 Month",
            },
            positions: ["Buy", "Sell", "Neutral"],
            symbol: '',
            timeInterval: '',
            assetFilter: '',
            position: '',
            mostReadIdeas: false,
            classification: '',
            SymbolClass: {
                'neutral': 'yellowBG',
                'buy': 'greenBG',
                'sell': 'redBG'
            },
            type: ''
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        login
    },
    watch : {
        '$route.query.id'(){
            if(Object.keys(this.store.user).length && this.store.user.profile){
                if(!this.$route.query.id){
                    this.callTradeIdeas()
                }else{
                    this.callTradeIdeas()
                }
            }
        }
    },
    mounted() {
        const _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".filter-dropdown").length) {
                _that.showCalendar = false;
            }
        });
    },
    methods: {
        handleDate() {
            if (this.date[0], this.date[1]) {
                this.showCalendar = false
                //this.$refs.datepicker.closeMenu()
                this.from = moment(this.date[0]).format('YYYY-MM-DD')
                this.to = moment(this.date[1]).format('YYYY-MM-DD')
                this.callTradeIdeas()
            }
        },
        goToDetailPage(item) {
            let title = item.title
            if (title.includes('-')) {
                title = title.replace(/-+/g, '')
            } else {
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if (titl.includes('--')) {
                    title = titl.replace(/--+/g, '-')
                } else {
                    title = item.title
                }
            }
            this.$router.push({
                name: 'details',
                params: { 'id': item.trade_id, 'title': title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'), 'page': this.page }
            });
            /*/ +/g, '-'*/
        },
        likeIdea(item, type) {
            if (Object.keys(this.store.user).length > 0 && this.store.user.profile) {
                this.showPopup = ''
                if (type == 'like') {
                    this.callUpdateTrade(item)
                } else {
                    this.goToDetailPage(item)
                }
            } else {
                this.showPopup = item.trade_id
            }
        },
        callUpdateTrade(item) {
            let form = {
                "type": "TRADE", "is_like": 1, "user_id": this.store.user.profile.user_id, "target_id": item.trade_id
            }
            if (parseInt(item.is_like) == 1) {
                form['is_like'] = 0
            }
            if (this.store.tradeIdeasList.totalRecords) {
                this.store.tradeIdeasList.records.forEach(val => {
                    if (parseInt(val.trade_id) == parseInt(item.trade_id)) {
                        if (parseInt(val.is_like) == 1) {
                            val.is_like = 0
                            val.likes_count = parseInt(val.likes_count) - 1
                        } else {
                            val.is_like = 1
                            val.likes_count = parseInt(val.likes_count) + 1
                        }
                    }
                })
            }
            this.store.callUpdateIdeas(form, true)
        },
        formatDate(val) {
            return moment(val).format('DD MMM')
        },
        loadMore() {
            this.page += 1
            this.callTradeIdeas()
        },
        callTradeIdeas() {
            let formData = {
                page: parseInt(this.page),
                perPage: parseInt(this.perPage),
                "sort": { "field": "trade_ideas.created", "type": "DESC" },
                filter: {}
            };
            if (this.type) {
                //formData.filter = {trade_ideas : ''}
                //formData.filter.trade_ideas = {type : ''}
                formData.filter['trade_ideas.type'] = this.type
            }
            if (this.from && this.to) {
                formData['start_date'] = this.from
                formData['end_date'] = this.to
            }
            if (this.assetFilter != '') {
                formData.filter['trade_ideas.category'] = this.assetFilter
            }
            if (this.mostReadIdeas) {
                formData['sort'] = { "field": "trade_ideas.view_count", "type": "DESC" }
            }
            if (this.classification && this.classification != 'All') {
                formData.filter['classification'] = this.classification
            }
            if (this.position) {
                formData.filter['position'] = this.position
            }
            if (this.timeInterval) {
                formData.filter['time_interval'] = this.timeInterval
            }
            if (this.symbol) {
                formData.filter['symbol'] = this.symbol
            }
            if (Object.keys(this.store.user).length && this.store.user.profile) {
                formData['user_id'] = this.store.user.profile.user_id
                formData['logged_user_id'] = this.store.user.profile.user_id
            }
            if (this.$route.query.id) {
                formData['user_id'] = this.$route.query.id
            }
            this.store
                .callTradeIdeasList(formData, false)
                .then((response) => {
                })
                .catch();
        }
    },
    created() {
        this.callTradeIdeas()
    }
}
</script>

<template>
    <div class="container">
        <div class="row space">
            <div class="col-12 mb-4">
                <ul class="toplinks pb-2 d-flex align-items-center border-bottom">
                    <li class="ps-0"><a @click.prevent="profileTab = 'Personal'" :class="(profileTab == 'Personal')?'active':''" href="javascript:void(0)">Personal</a></li>
                    <!-- <li><a @click.prevent="profileTab = 'Notifications'" :class="(profileTab == 'Notifications')?'active':''" href="javascript:void(0)"> Notifications</a></li>
                    <li><a @click.prevent="profileTab = 'Sharing'" :class="(profileTab == 'Sharing')?'active':''" href="javascript:void(0)"> Sharing</a></li>
                    <li><a @click.prevent="profileTab = 'Privacy'" :class="(profileTab == 'Privacy')?'active':''" href="javascript:void(0)">Privacy</a></li> -->
                </ul>
            </div>
            <div class="col-12" v-if="profileTab == 'Personal'">
                <div class="myProfile" v-if="Object.keys(store.userProfile).length">
                    <div class="editPrifileImage d-flex align-items-center mb-4">
                       <!--  <img src="assets/images/prifile_image.png" alt="Image" title="Jezlin Jacob"> -->
                       <p class="IdeaUsername mb-0 profile large">{{store.userProfile.firstname.charAt(0) ||
                                    ''}}{{store.userProfile.lastname.charAt(0) || ''}}</p>
                        <div class="flex-grow-1 ms-4" v-if="!editProfile">
                            <h5 class="mb-2">{{store.userProfile.firstname}} {{store.userProfile.lastname}}</h5>
                           <!--  <p>Chandigarh, India</p> -->
                            <a href="javascript:void(0)" class="border-btn small" @click="editProfile =!editProfile">Edit Profile</a>
                        </div>
                        <!-- <div class="flex-grow-1 d-flex align-items-center ms-4" v-if="editProfile">
                            <a href="javascript:void(0)" class="me-2 border-btn greenBG text-white">Upload new picture</a>
                            <a href="javascript:void(0)" class="gray_btn">Delete</a>
                        </div>  -->
                    </div>
                    <Form class="alert-form" @submit="updateProfile">
                        <div class="form-group position-relative">
                            <label>Username</label>
                            <Field
                                type="text"
                                class="form-control"
                                name="username"
                                v-model="form.username"
                                disabled
                              />
                        </div>
                        <div class="dualInput d-flex align-items-center justify-content-between">
                            <!-- <div class="form-group pe-3">
                                <label>Location</label>
                                 <input type="text" class="form-control" placeholder="Chandigarh, India" name="">
                            </div>
                            <div class="form-group">
                                <label>Twitter handle</label>
                                 <input type="text" class="form-control" placeholder="@jezlin" name="">
                            </div> -->
                            <div class="form-group pe-3">
                                <label>First Name</label>
                                <Field
                                    type="text"
                                    class="form-control"
                                    name="first name"
                                    v-model="form.fname"
                                    rules="required"
                                    :disabled="editProfile ? false : true"
                                  />
                                  <ErrorMessage
                                    as="paragraph"
                                    name="first name"
                                    class="text-danger mb-0"
                                  />
                            </div>
                            <div class="form-group">
                                <label>Last Name</label>
                              <Field
                                type="text"
                                class="form-control"
                                name="last name"
                                v-model="form.lname"
                                rules="required"
                                :disabled="editProfile ? false : true"
                              />
                              <ErrorMessage
                                    as="paragraph"
                                    name="last name"
                                    class="text-danger mb-0"
                                  />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <Field
                                type="text"
                                class="form-control"
                                name="email"
                                v-model="form.email"
                                rules="required"
                                :disabled="editProfile ? false : true"
                              />
                               <ErrorMessage
                                as="paragraph"
                                name="email"
                                class="text-danger mb-0"
                              />
                        </div>
                        <!-- <div class="form-group pb-3">
                            <label>Bio</label>
                           <textarea type="text" class="form-control" placeholder="What is a profile on a resume. A profile on a resume is a written statement located at the top of your resume. "></textarea>
                        </div> -->
                        <div class="form-group" v-if="editProfile">
                           <a href="javascript:void(0)" class="border-btn green large me-3" @click="editProfile = false">Cancel</a>
                           <button class="grd_btn">Save Profile</button>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="col-12" v-if="profileTab == 'Notifications'">
                <notifications/>
            </div>
            <div class="col-12" v-if="profileTab == 'Sharing'">
                <sharing/>
            </div>
            <div class="col-12" v-if="profileTab == 'Privacy'">
                <privacy />
            </div>
        </div>
    </div>
</template>

<script>
import notifications from "./notifications";
import sharing from "./sharing";
import privacy from "./privacy";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useMainStore } from "@/store";
export default {
     setup() {
    const store = useMainStore();
    return { store };
  },
    data() {
        return {
            profileTab: 'Personal',
            editProfile : false,
            form : {
                username : '',
                fname : '',
                lname : '',
                email : ''
            }
        }
    },    
    components: {
        notifications,
        sharing,
        privacy,
        Form,
        Field,
        ErrorMessage,
    },
    methods:{
        updateProfile(){
            let form = {
                'firstname' : this.form.fname,
                'lastname' : this.form.lname
            }
            if(this.form.email.trim() != this.store.userProfile.email){
                form['email']  = this.form.email
            }
            this.store.updateUserProfile(form,true,this)
        },
        reset(){
            this.editProfile = false
            this.$parent.callUserProfile()
        }
    },
    created(){
        if(Object.keys(this.store.userProfile).length){
            this.form.username = this.store.userProfile.username
            this.form.fname = this.store.userProfile.firstname
            this.form.lname = this.store.userProfile.lastname
            this.form.email = this.store.userProfile.email
        }
    }
}
</script>

<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement profile-banner pb-0" v-if="Object.keys(UserData).length">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-4"> 
                        <div class="updateProfile d-flex align-items-center mb-4">
                            <span class="position-relative letterName">{{UserData.firstname.charAt(0)}}{{UserData.lastname.charAt(0)}}</span>
                            
                            <div class="ms-3 flex-grow-1 followSquere">
                                <h5 class="mb-0">{{UserData.firstname || ''}} {{UserData.lastname || ''}}</h5>
                                <p class="lighttext f-12 mb-2">Joined On : {{JoinedDate(UserData.created)}}</p>
                                <!-- After Login -->
                                <a href="javascript:void(0)" class="small py-2 border-btn greenBG d-inline-flex align-items-center justify-content-center" aria-current="page" @click="UnfollowUser()" v-if="$route.query.id && isLogin && store.userProfile.userFollowing"><vue-feather class="me-1" size="16" type="user-plus"></vue-feather> Unfollow </a>
                                <a href="javascript:void(0)" class="small py-2 border-btn d-inline-flex align-items-center justify-content-center"  aria-current="page" @click="followUser()" v-if="$route.query.id && isLogin && !store.userProfile.userFollowing"><vue-feather class="me-1" size="16" type="user-plus"></vue-feather> Follow </a>
                               <!--  Before login -->
                               <a href="javascript:void(0)" class="small py-2 border-btn d-inline-flex align-items-center justify-content-center" aria-current="page" @click="UnfollowUser()" v-if="$route.query.id  && !isLogin && store.anotherUserProfile.userFollowing"><vue-feather class="me-1" size="16" type="user-plus"></vue-feather> Unfollow </a>
                                <a href="javascript:void(0)" class="small py-2 border-btn greenBG d-inline-flex align-items-center justify-content-center"  aria-current="page" @click="followUser()" v-if="$route.query.id && !isLogin && !store.anotherUserProfile.userFollowing"><vue-feather class="me-1" size="16" type="user-plus"></vue-feather> Follow </a>
                                <!-- <div class="likes d-flex align-items-center">
                                    <span class="d-flex align-items-center"><img src="assets/images/likes.png" alt="Icon" title="Like"> 38</span>
                                    <span class="d-flex align-items-center"><img src="assets/images/following.png" alt="Icon" title="Following"> 30</span>
                                    <span class="d-flex align-items-center"><img src="assets/images/idea.png" alt="Icon" title="Idea"> 160</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                   <!--  <div class="col-12 col-lg-8 py-4 py-lg-0"> 
                        <div class="d-flex align-items-center justify-content-end profileElement">
                            <ul class="me-md-5 proflileScore d-flex align-items-center mb-0">
                                <li>
                                    <p class="f-16 mb-0">0%</p>
                                    <p class="lighttext mb-0">Crypto</p>
                                </li>
                                <li>
                                    <p class="f-16 mb-0">0%</p>
                                    <p class="lighttext mb-0">Stocks</p>
                                </li>
                                <li>
                                    <p class="f-16 mb-0">0%</p>
                                    <p class="lighttext mb-0">Forex</p>
                                </li>
                                <li>
                                    <p class="f-16 mb-0">0%</p>
                                    <p class="lighttext mb-0">Indices</p>
                                </li>
                                <li>
                                    <p class="f-16 mb-0">0%</p>
                                    <p class="lighttext mb-0">Others</p>
                                </li>
                            </ul>
                            <span class="profileChart"><img src="assets/images/chart/profilechart.svg" alt="Chart" title="Profile Chart"></span>
                        </div>
                    </div> -->
                    <div class="col-12">
                        <ul class="toplinks visible pb-2 d-flex align-items-center mb-0">
                            <li class="ps-0"><a @click.prevent="profileTab = 'Ideas'" :class="(profileTab == 'Ideas')?'active':''" href="javascript:void(0)"> Ideas</a></li>
                            <li v-if="checkUser()"><a @click.prevent="profileTab = 'Followers'" :class="(profileTab == 'Followers')?'active':''" href="javascript:void(0)"> Followers</a></li>
                            <li class="hideMobile" v-if="checkUser()"><a @click.prevent="profileTab = 'Following'" :class="(profileTab == 'Following')?'active':''" href="javascript:void(0)"> Following</a></li>
                            <li class="hideMobile" v-if="checkUser()"><a @click.prevent="profileTab = 'Strategies'" :class="(profileTab == 'Strategies')?'active':''" href="javascript:void(0)"> Strategies</a></li>
                            <li class="hideMobile" v-if="checkUser() && !$route.query.id"><a @click.prevent="profileTab = 'Portfolio'" :class="(profileTab == 'Portfolio')?'active':''" href="javascript:void(0)"> My Portfolio</a></li>
                            <li class="position-relative" v-if="checkUser() && !$route.query.id">
                                <a  @click="dropDown = !dropDown" href="javascript:void(0)" :class="(profileTab == 'Alerts' || profileTab == 'Saved' || profileTab == 'Voting' || profileTab == 'myProfile' || profileTab == 'Sentiments' || profileTab == 'watchlist' || profileTab == 'Discussions') ? 'active' : ''" class="d-flex align-items-center"> Settings <vue-feather size="20px" type="chevron-down"></vue-feather> </a>
                                <div class="dropdown_menu_animated max-400 p-md-3" :class="dropDown?'show':''">
                                    <ul class="d-flex flex-wrap settingDropdown">
                                        <!-- Show three menu on molile repet destop -->
                                        <li class="showMoile"><a @click.prevent="profileTab = 'Following';dropDown = false" :class="(profileTab == 'Following')?'active':''" href="javascript:void(0)" class="d-flex  align-items-center"><span class="maskImg menu4"></span> Following</a></li>
                                        <li class="showMoile"><a @click.prevent="profileTab = 'Strategies';dropDown = false" :class="(profileTab == 'Strategies')?'active':''" href="javascript:void(0)" class="d-flex  align-items-center"> <span class="maskImg menu3"></span>Strategies</a></li>
                                        <li class="showMoile"><a @click.prevent="profileTab = 'Portfolio';dropDown = false" :class="(profileTab == 'Portfolio')?'active':''" href="javascript:void(0)" class="d-flex  align-items-center"><span class="maskImg menu5"></span> My Portfolio</a></li>
                                        <!-- End -->
                                        <li><a @click.prevent="profileTab = 'Alerts';dropDown = false" :class="(profileTab == 'Alerts')?'active':''" href="javascript:void(0)" class="d-flex align-items-center"><span class="maskImg menu1"></span> Alerts</a></li>
                                        <li><a @click.prevent="profileTab = 'Saved';dropDown = false" :class="(profileTab == 'Saved')?'active':''" href="javascript:void(0)" class="d-flex align-items-center"> <span class="maskImg menu2"></span>Saved Items</a></li>
                                        <li><a @click.prevent="profileTab = 'Voting';dropDown = false" :class="(profileTab == 'Voting')?'active':''" href="javascript:void(0)" class="d-flex align-items-center"><span class="maskImg menu3"></span> Voting</a></li>
                                        <li><a @click.prevent="profileTab = 'myProfile';dropDown = false" :class="(profileTab == 'myProfile')?'active':''" href="javascript:void(0)" class="d-flex align-items-center"><span class="maskImg menu4"></span> My Profile</a></li>
                                        <li><a @click.prevent="profileTab = 'Sentiments';dropDown = false" :class="(profileTab == 'Sentiments')?'active':''" href="javascript:void(0)" class="d-flex align-items-center"><span class="maskImg menu5"></span> Sentiments</a></li>
                                        <li><a @click.prevent="profileTab = 'watchlist';dropDown = false" :class="(profileTab == 'watchlist')?'active':''" href="javascript:void(0)" class="d-flex align-items-center"><span class="maskImg menu6"></span> My watchlist </a></li>
                                        <li><a @click.prevent="profileTab = 'Discussions';dropDown = false" :class="(profileTab == 'Discussions')?'active':''" href="javascript:void(0)" class="d-flex align-items-center"><span class="maskImg menu7"></span> Discussions</a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement ideaBlock bg-white spaceElement" v-if="profileTab == 'Ideas'">
            <app-idea></app-idea>
        </section>
        <section class="blockElement followersBlock bg-white spaceElement" v-if="profileTab == 'Followers'">
            <appfollowers></appfollowers>
        </section>
        <section class="blockElement followingBlock bg-white spaceElement" v-if="profileTab == 'Following'">
            <appfollowing></appfollowing>
        </section>
        <section class="blockElement strategiesBlock bg-white spaceElement" v-if="profileTab == 'Strategies'">
            <!-- <appstrategies></appstrategies> -->
            <sentiments/>
        </section>
        <section class="blockElement portfolioBlock bg-white spaceElement" v-if="profileTab == 'Portfolio'">
            <!-- <appMyportfolio></appMyportfolio> -->
            <sentiments/>
        </section>
        <section class="blockElement alertBlock bg-white spaceElement" v-if="profileTab == 'Alerts'">
            <!-- <appalert></appalert> -->
            <sentiments/>
        </section>
        <section class="blockElement alertBlock bg-white spaceElement" v-if="profileTab == 'Saved'">
           <!--  <appsaveditems></appsaveditems> -->
           <sentiments/>
        </section>
        <section class="blockElement alertBlock bg-white spaceElement" v-if="profileTab == 'Voting'">
            <!-- <appvoting></appvoting> -->
            <sentiments/>
        </section>
        <section class="blockElement alertBlock bg-white spaceElement" v-if="profileTab == 'myProfile'">
            <appmyprofile></appmyprofile>
        </section>
        <section class="blockElement alertBlock bg-white spaceElement" v-if="profileTab == 'Sentiments'">
            <sentiments/>
        </section>
        <section class="blockElement watchlistBlock bg-white spaceElement" v-if="profileTab == 'watchlist'">
           <!--  <watchlist/> -->
           <sentiments/>
        </section>
        <section class="blockElement discussionsBlock bg-white spaceElement" v-if="profileTab == 'Discussions'">
           <!--  <discussions/> -->
           <sentiments/>
        </section>
        <login v-if="showPopup"></login>
    </div>
</template>

<script>
import { useMainStore } from "@/store";
import bannerslider from "../../components/shared/banner_slider";
import idea from "./files/idea";
import followers from "./files/followers";
import following from "./files/following";
import strategies from "./files/strategies";
import myportfolio from "./files/my_portfolio";
import alert from "./files/alert";
import appsaved_items from "./files/saved_items";
import voting from "./files/voting";
import myprofile from "./files/my_profile";
import sentiments from "./files/sentiments";
import watchlist from "./files/watchlist";
import discussions from "./files/discussions";
import moment from 'moment'
import login from "../../components/shared/userlogin"
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            profileTab: 'Ideas',
            dropDown: false,
            showPopup : '',
        }
    },
    components: {
        bannerSlider:bannerslider,
        appIdea:idea,
        appfollowers:followers,
        appfollowing:following,
        appstrategies:strategies,
        appMyportfolio:myportfolio,
        appalert:alert,
        appsaveditems:appsaved_items,
        appvoting:voting,
        appmyprofile:myprofile,
        sentiments,
        watchlist,
        discussions,
        login

    },  
    computed:{
        UserData(){
            if(Object.keys(this.store.user).length && this.store.user.profile){
                if(this.$route.query.id && parseInt(this.$route.query.id) != parseInt(this.store.user.profile.user_id)){
                     return this.store.userProfile
                }else{
                    return this.store.userProfile
                }
            }else{
                if(this.$route.query.id){
                    return this.store.anotherUserProfile
                }else{
                    return this.store.userProfile
                }
            }
        }
    },
    watch : {
        '$route.query.id'(){
            if(Object.keys(this.store.user).length && this.store.user.profile){
                if(!this.$route.query.id){
                    this.callUserProfile()
                }else{
                    this.callUserProfile()
                    this.profileTab = 'Ideas'
                }
            }

        }
    },
    methods:{
        isLogin(){
            if(Object.keys(this.store.user).length && this.store.user.profile){
                if(this.$route.query.id && parseInt(this.$route.query.id) != parseInt(this.store.user.profile.user_id)){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        },
        UnfollowUser(){
            if(Object.keys(this.store.user).length && this.store.user.profile){
                this.showPopup = ''
                this.store.userProfile.userFollowing = null
                this.store.$patch({userProfile : this.store.userProfile})
                let form = {
                    "follower_id" : this.store.userProfile.user_id
                };
                this.store.callUnFollowUser(form,true)
            }else{
                this.showPopup = 1
            }
        },
        followUser(){
            if(Object.keys(this.store.user).length && this.store.user.profile){
                this.showPopup = ''
                 this.store.userProfile.userFollowing = 1
                this.store.$patch({userProfile : this.store.userProfile})
                let form = {
                    "follower_id" : this.store.userProfile.user_id
                };
                this.store.callFollowUser(form,true)
            }else{
                this.showPopup = 1
            }
        },
        checkUser(){
            if(Object.keys(this.store.user).length && this.store.user.profile){
                if(this.$route.query.id && parseInt(this.$route.query.id) != parseInt(this.store.user.profile.user_id)){
                    return false
                }else{
                    return true
                }
            }else{
                return false
            }
        },
        JoinedDate(date){
            return moment(date).format('DD MMM YYYY')
        },
        callUserProfile(){
             let form  = {};
            if(this.$route.query.id && parseInt(this.$route.query.id) != parseInt(this.store.user.profile.user_id)){
                form['follower_id'] = this.$route.query.id
            }
            this.store
          .getUserProfile(form, false)
          .then((response) => {
          })
          .catch();
        },
        callAnotherUserProfile(){
            let form ={
                'user_id' : this.$route.query.id
            }
            this.store
          .getAnotherUserProfile(form, false)
          .then((response) => {
          })
          .catch();
        }
    },
    created(){
        if(Object.keys(this.store.user).length && this.store.user.profile){
             if(this.$route.query.id && parseInt(this.$route.query.id) != parseInt(this.store.user.profile.user_id)){
                this.callUserProfile()
             }else{
                this.callUserProfile()
             }
        }else{
            if(this.$route.query.id){
                this.callAnotherUserProfile()
            }else{
                this.callUserProfile()
            }
        }
    }  
}
</script>

<template>
    <div class="settingPage d-flex align-items-start h-100">
        <div class="card-body customMax p-0">
            <ul class="settingTabs mb-0">
                <li><a @click.prevent="profileTab = 'Email'" :class="(profileTab == 'Email')?'active':''" href="javascript:void(0)">Email Notification</a></li>
                <li><a @click.prevent="profileTab = 'Updates'" :class="(profileTab == 'Updates')?'active':''" href="javascript:void(0)">Updates about you</a></li>
                <li><a @click.prevent="profileTab = 'Push'" :class="(profileTab == 'Push')?'active':''" href="javascript:void(0)">Push Notification</a></li>
            </ul>
        </div>
        <div class="card-body rightView">
            <div class="showTabs" v-if="profileTab == 'Email'">
                <h5 class="medium mb-2">Email Notification:</h5> 
                <div class="d-flex align-items-center justify-content-between border-bottom pb-4" >
                    <p class="mb-0">When certain push notifications are on, we skip sending the same info via email. <a href="javascript:void(0)" class="green">Learn more</a></p> 
                    <label class="toggleSwitch">
                        <input type="checkbox"> 
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="showTabs" v-if="profileTab == 'Updates'">
                <h5 class="medium mb-3">Updates about you</h5> 
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Email Notification:</h6> 
                        <p class="mb-0">Email me when someone sends me a message</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <h5 class="medium mb-3">Replies to my comments</h5> 
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Suggested</h6> 
                        <p class="mb-0">Weekly highlights based on your groups and interests</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">New Meetup groups</h6> 
                        <p class="mb-0">Tell me about new Groups that match my interests</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Updates from Portfolios HQ</h6> 
                        <p class="mb-0">Tell me about new featured  and</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div class="showTabs" v-if="profileTab == 'Push'">
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <h5 class="medium mb-0">Push Notification</h5> 
                    <label class="toggleSwitch">
                        <input type="checkbox"> 
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Message</h6> 
                        <p class="mb-0">When someone sends me a message</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">New Follower</h6> 
                        <p class="mb-0">When someone follow my profile</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Liked</h6> 
                        <p class="mb-0">When someone likes my profile</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Reshared my post</h6> 
                        <p class="mb-0">When someone reshares my post</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Replies to my comments</h6> 
                        <p class="mb-0">When someone replies to my comments</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Mention me in comments</h6> 
                        <p class="mb-0">When someone mentions me in thier </p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Recent Activity</h6> 
                        <p class="mb-0">When someone adds comment in the thread created by me</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">Following</h6> 
                        <p class="mb-0">A post by person whom i’m following</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between pb-4" >
                    <div class="content">
                        <h6 class="mb-1">New Groups</h6> 
                        <p class="mb-0">Tell me about new groups that match my interests</p> 
                    </div>
                    <label class="customCheckbox">
                        <input type="checkbox"> 
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            profileTab:'Email'
        }
    },    
}
</script>

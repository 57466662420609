<template>
    <div class="container space-footer">
        <div class="row">
            <div class="col-12 mb-4">
                <ul class="toplinks pb-2 d-flex align-items-center border-bottom">
                    <li class="ps-0"><a @click.prevent="profileTab = 'Events'" :class="(profileTab == 'Events')?'active':''" href="javascript:void(0)">Economic Events</a></li>
                    <li><a @click.prevent="profileTab = 'priceAlert'" :class="(profileTab == 'priceAlert')?'active':''" href="javascript:void(0)"> Price Alert</a></li>
                    <li><a @click.prevent="profileTab = 'Actions'" :class="(profileTab == 'Actions')?'active':''" href="javascript:void(0)"> Editor’s Actions</a></li>
                    <li><a @click.prevent="profileTab = 'Logs'" :class="(profileTab == 'Logs')?'active':''" href="javascript:void(0)"> Event Logs</a></li>
                </ul>
            </div>
            <div class="alertTabShaow" v-if="profileTab == 'Events'">
                <div class="noData text-center">
                    <img class="mb-3" src="assets/images/event.svg" alt="Icon" title="No data found">
                    <p class="mb-4">Alerts are immediate notifications when your conditions are met. Create your first alert to get started!</p>
                    <router-link to="/economic-calendar" class="greenBtn large" @click="createAlert = '2'"> Create Alert </router-link>
                </div>
            </div>
            <div class="alertTabShaow" v-if="profileTab == 'priceAlert'">
                <div class="col-12" v-show="createAlert == '1'">
                    <div class="noData text-center">
                        <img class="mb-3" src="assets/images/nodata.svg" alt="Icon" title="No data found">
                        <p class="mb-4">Alerts are immediate notifications when your conditions are met. Create your first alert to get started!</p>
                        <a href="javascript:void(0)" class="greenBtn large" @click="createAlert = '2'"> Create Alert </a>
                    </div>
                </div>
                <div class="col-12" v-show="createAlert == '2'">
                    <div class="setAlert">
                        <h6 class="text-center mb-4">Create Alert</h6>
                        <form class="alert-form">
                            <div class="form-group position-relative inputIcon">
                                <input type="text" class="form-control" placeholder="Search Scrip Name" name="">
                                <a class="searchButton" href="#"><vue-search type="search"></vue-search></a>
                            </div>
                            <div class="form-group">
                                <label class="mb-2">Alert me</label>
                                <select class="form-control form-select">
                                    <option>Grater than equal to</option>
                                    <option>Less than equal to</option>
                                </select>
                            </div>
                            <div class="form-group">
                                 <input type="text" class="form-control" placeholder="Enter Value" name="">
                            </div>
                            <div class="form-group">
                                <label class="mb-2">Type</label>
                                <select class="form-control form-select">
                                    <option>Last Traded Price</option>
                                    <option>Last Traded Price</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="mb-2">Validity</label>
                                <select class="form-control form-select">
                                    <option>Good Till Trigger</option>
                                    <option>Good Till Trigger</option>
                                </select>
                            </div>
                            <div class="form-group pb-3">
                               <textarea type="text" class="form-control" placeholder="Add note"></textarea>
                            </div>
                            <div class="form-group text-center">
                               <a @click="createAlert = '3'" href="javascript:void(0)" class="grd_btn">Set Alert</a>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-12" v-show="createAlert == '3'">
                    <div class="table-responsive snapTable">
                        <table class="table borderedTable nowrap">
                            <thead>
                                <tr>
                                    <th>Scip Name</th>
                                    <th>Alert Me</th>
                                    <th>Value</th>
                                    <th>Notes</th>
                                    <th align="right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>EURUSD</td>
                                    <td>Greater than equal to</td>
                                    <td>4050</td>
                                    <td>Once</td>
                                    <td>
                                        <span class="d-flex align-items-end flex-column position-relative">
                                            <a @click="dropDown = !dropDown" href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                            <div class="dropdown_menu_animated" :class="dropDown?'show':''">
                                                <li><a href="javascript:void(0)">Remove</a></li>
                                                <li><a href="javascript:void(0)">Block</a></li>
                                            </div>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>EURUSD</td>
                                    <td>Greater than equal to</td>
                                    <td>4050</td>
                                    <td>Once</td>
                                    <td>
                                        <span class="d-flex align-items-end flex-column position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>EURUSD</td>
                                    <td>Greater than equal to</td>
                                    <td>4050</td>
                                    <td>Once</td>
                                    <td>
                                        <span class="d-flex align-items-end flex-column position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>EURUSD</td>
                                    <td>Greater than equal to</td>
                                    <td>4050</td>
                                    <td>Once</td>
                                    <td>
                                        <span class="d-flex align-items-end flex-column position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>EURUSD</td>
                                    <td>Greater than equal to</td>
                                    <td>4050</td>
                                    <td>Once</td>
                                    <td>
                                        <span class="d-flex align-items-end flex-column position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>EURUSD</td>
                                    <td>Greater than equal to</td>
                                    <td>4050</td>
                                    <td>Once</td>
                                    <td>
                                        <span class="d-flex align-items-end flex-column position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>EURUSD</td>
                                    <td>Greater than equal to</td>
                                    <td>4050</td>
                                    <td>Once</td>
                                    <td>
                                        <span class="d-flex align-items-end flex-column position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="alertTabShaow" v-if="profileTab == 'Actions'">
                <div class="noData text-center">
                    <img class="mb-3" src="assets/images/underprocess.svg" alt="Icon" title="No data found">
                    <p class="mb-4">This page is under process</p>
                </div>
            </div>
            <div class="alertTabShaow" v-if="profileTab == 'Logs'">
                <div class="noData text-center">
                    <img class="mb-3" src="assets/images/underprocess.svg" alt="Icon" title="No data found">
                    <p class="mb-4">This page is under process</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            profileTab: 'priceAlert',
            createAlert : '1',
            dropDown: false
        }
    },    
}
</script>

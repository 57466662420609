<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="row" v-show="demoWatchlist == '1'">
                    <div class="col-12 d-flex align-items-center justify-content-between mb-3">
                        <h5 class="mb-0">My watchlist </h5>
                        <a href="javascript:void(0)" class="grd_btn small"><vue-feather class="me-1" size="16px" type="plus"></vue-feather> Register</a>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="card-body myWatchList">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h6 class="mb-0">Demo Watchlist </h6>
                                <div class="rtBtn d-flex align-items-center">
                                    <a href="javascript:void(0)" class="me-3 iconcolor gray_btn d-flex align-items-center">Public <vue-feather class="ms-2" size="16px" type="check"></vue-feather></a>
                                    <a @click="demoWatchlist = '2'" href="javascript:void(0)" class="gray_btn iconcolor">Open</a>
                                </div>
                            </div>
                            <div class="chartContent">
                                <p class="lighttext">Tickers: 2 <span class="ms-3">Public Views: 0</span></p>
                                <img class="mb-3 w-100" src="assets/images/chart/watchlist.svg" alt="Image" title="Demo Watchlist ">
                                <p class="lighttext mb-0 text-center">Updated: 17 minutes ago</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="card-body myWatchList">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h6 class="mb-0">Demo Watchlist </h6>
                                <div class="rtBtn d-flex align-items-center">
                                    <a href="javascript:void(0)" class="me-3 iconcolor gray_btn d-flex align-items-center">Public <vue-feather class="ms-2" size="16px" type="check"></vue-feather></a>
                                    <a href="javascript:void(0)" class="gray_btn iconcolor">Open</a>
                                </div>
                            </div>
                            <div class="chartContent">
                                <p class="lighttext">Tickers: 2 <span class="ms-3">Public Views: 0</span></p>
                                <img class="mb-3 w-100" src="assets/images/chart/watchlist.svg" alt="Image" title="Demo Watchlist ">
                                <p class="lighttext mb-0 text-center">Updated: 17 minutes ago</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="card-body myWatchList">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h6 class="mb-0">Demo Watchlist </h6>
                                <div class="rtBtn d-flex align-items-center">
                                    <a href="javascript:void(0)" class="me-3 iconcolor gray_btn d-flex align-items-center">Public <vue-feather class="ms-2" size="16px" type="check"></vue-feather></a>
                                    <a href="javascript:void(0)" class="gray_btn iconcolor">Open</a>
                                </div>
                            </div>
                            <div class="chartContent">
                                <p class="lighttext">Tickers: 2 <span class="ms-3">Public Views: 0</span></p>
                                <img class="mb-3 w-100" src="assets/images/chart/watchlist.svg" alt="Image" title="Demo Watchlist ">
                                <p class="lighttext mb-0 text-center">Updated: 17 minutes ago</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="card-body myWatchList">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h6 class="mb-0">Demo Watchlist </h6>
                                <div class="rtBtn d-flex align-items-center">
                                    <a href="javascript:void(0)" class="me-3 iconcolor gray_btn d-flex align-items-center">Public <vue-feather class="ms-2" size="16px" type="check"></vue-feather></a>
                                    <a href="javascript:void(0)" class="gray_btn iconcolor">Open</a>
                                </div>
                            </div>
                            <div class="chartContent">
                                <p class="lighttext">Tickers: 2 <span class="ms-3">Public Views: 0</span></p>
                                <img class="mb-3 w-100" src="assets/images/chart/watchlist.svg" alt="Image" title="Demo Watchlist ">
                                <p class="lighttext mb-0 text-center">Updated: 17 minutes ago</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-show="demoWatchlist == '2'">
                    <demoWatchlist />
                </div>
            </div>
            <div class="col-12 col-lg-4 ">
                <h5 class="mb-4 pb-1">Most Viewed Watchlist</h5>
                <ul class="userviewList">
                    <li>
                        <p class="mb-0 f-15">User</p>
                        <p class="mb-0 f-15">Views</p>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                           <span class="yellowtheme byName me-2">B</span> 
                           <span class="name">Buyers</span> 
                        </span>
                        <p class="mb-0 f-15">3148</p>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                           <span class="redtheme byName me-2">E</span> 
                           <span class="name">Earning Monday</span> 
                        </span>
                        <p class="mb-0 f-15">1500</p>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                           <span class="bluetheme byName me-2">M</span> 
                           <span class="name">My Top Followed</span> 
                        </span>
                        <p class="mb-0 f-15">653</p>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                           <span class="yellowtheme byName me-2">T</span> 
                           <span class="name">Tech Leader</span> 
                        </span>
                        <p class="mb-0 f-15">496</p>
                    </li>
                     <li>
                        <span class="d-flex align-items-center">
                           <span class="redtheme byName me-2">S</span> 
                           <span class="name">Shorters</span> 
                        </span>
                        <p class="mb-0 f-15">270</p>
                    </li>
                    <li>
                        <span class="d-flex align-items-center">
                           <span class="yellowtheme byName me-2">B</span> 
                           <span class="name">Buyers</span> 
                        </span>
                        <p class="mb-0 f-15">653</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import demoWatchlist from "./demo_watchlist";
export default {
    data() {
        return {
            demoWatchlist: '1'
        }
    },    
    components: {
        demoWatchlist
    },
}
</script>
